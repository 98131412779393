import React, { Component } from "react";
import StarRatings from "react-star-ratings";
import Navigation from "../../../components/Navigation";
import { returnString } from "../strings";

export default class StepRatings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      back_path: "/survey/step-priorities",
      next_path: "/survey/step-influence",
      country: this.props.data.country,
      tokenType: this.props.data.tokenType,
      strings: null,
      context: this.props.data.context,
    };
  }

  get completed() {
    if (this.props.data.list.filter((it) => it.rating === 0).length === 0) {
      localStorage.setItem("stepRatings", JSON.stringify(this.props.data.list));
      return true;
    } else {
      return false;
    }
  }

  UNSAFE_componentWillMount() {
    let strings = returnString(this.state.tokenType, this.state.context);

    this.setState({
      strings: strings,
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  changeComments = (event) => {
    const motivatorsIndex = event.target.dataset.index;
    const value = event.target.value;
    const listCopy = [...this.props.data.list];
    listCopy[motivatorsIndex].comments = value;
    this.props.onChange("list", listCopy);
  };

  changeRating = (newRating, name) => {
    const motivatorsIndex = name.replace("rating-", "");
    const listCopy = [...this.props.data.list];
    listCopy[motivatorsIndex].rating = newRating;
    this.props.onChange("list", listCopy);
  };

  setNavigation() {
    if (this.completed) {
      return (
        <Navigation back={this.state.back_path} next={this.state.next_path} />
      );
    } else {
      return <Navigation back={this.state.back_path} />;
    }
  }

  render() {
    return (
      <div className="step-ratings">
        <div className="mb-4">
          <h2 className="mb-3 text-bold">
            {this.state.strings[this.state.country].stepRatings.h2}
          </h2>
          {this.state.strings[this.state.country].stepRatings.text.map((value, i) => {
            return <p key={i} dangerouslySetInnerHTML={{ __html: value }} />;
          })}
        </div>
        <div className="motivators-container">
          {this.props.data.list.map((item, index, arr) => (
            <div key={item.id} className="card">
              <div className="card-title">
                <h3>
                  {index + 1}º {item.title}
                </h3>
              </div>

              <div className="card-content">
                <p>{item.description}</p>

                {this.state.strings[
                  this.state.country
                ].stepRatings.card_content.text.map((value, i) => {
                  return (
                    <p
                      className="mt-2 mb-1"
                      key={i}
                      dangerouslySetInnerHTML={{ __html: value }}
                    />
                  );
                })}
                <div className="rating">
                  <StarRatings
                    rating={item.rating}
                    changeRating={this.changeRating}
                    numberOfStars={10}
                    name={`rating-${index}`}
                    starDimension="22px"
                    starEmptyColor="#D3D3D3"
                    starHoverColor="#02A5A5"
                    starRatedColor="#02A5A5"
                  />
                  <div className="stars-tags">
                    {this.state.strings[
                      this.state.country
                    ].stepRatings.card_content.tags.map((value, i) => {
                      return (
                        <span
                          key={i}
                          dangerouslySetInnerHTML={{ __html: value }}
                        />
                      );
                    })}
                  </div>
                </div>

                {this.state.strings[
                  this.state.country
                ].stepRatings.card_content.textarea.map((value, i) => {
                  return (
                    <p
                      className="mt-1 mb-1"
                      key={i}
                      dangerouslySetInnerHTML={{ __html: value }}
                    />
                  );
                })}
                <textarea
                  placeholder="Tu respuesta (opcional)"
                  name="description"
                  value={item.comments}
                  onChange={this.changeComments}
                  data-index={index}
                />
              </div>
            </div>
          ))}
        </div>

        {this.setNavigation()}
      </div>
    );
  }
}
