import React, { Component } from "react";
import { ReactSortable } from "react-sortablejs";
import Navigation from "../../../components/Navigation";
import { returnString } from "../strings";

export default class StepPriorities extends Component {
  constructor(props) {
    super(props);

    let dirty = this.props.data.alreadySorted;
    this.state = {
      sorted: 0,
      total: 0 + this.props.data.list.length,
      list: dirty ? [] : this.props.data.list.slice(),
      result: dirty ? this.props.data.list.slice() : [],
      back_path: "/survey/step-disc",
      next_path: "/survey/step-ratings",
      country: this.props.data.country,
      tokenType: this.props.data.tokenType,
      strings: null,
      context: this.props.data.context,
    };
  }

  UNSAFE_componentWillMount() {
    let strings = returnString(this.state.tokenType, this.state.context);
    // let back_path = "/survey/step-disc";
    let back_path = "/survey/step-demographics";

    this.setState({
      strings: strings,
      back_path: back_path,
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    if (this.state.result.length === this.state.total) {
      this.props.onChange("alreadySorted", true);
      this.props.onChange("list", this.setOrderedList());
      localStorage.setItem("stepPriorities", JSON.stringify(this.state.result));
    }
  }

  setList = (newList) => this.setState({ list: newList });
  setResult = (newResult) => {
    this.setState({
      result: newResult,
      sorted: newResult.length,
    });
  };

  setOrderedList = () => {
    this.state.result.map((item, index) => {
      item.position = index + 1;
      return true;
    });

    return this.state.result;
  };

  setNavigation() {
    if (this.state.result.length === this.state.total) {
      return (
        <Navigation back={this.state.back_path} next={this.state.next_path} />
      );
    } else {
      return <Navigation back={this.state.back_path} />;
    }
  }

  render() {
    return (
      <div className="step-priorities">
        <div className="mb-2">
          <h2 className="mb-3 text-bold">
            {this.state.strings[this.state.country].stepPriorities.h2}
          </h2>
          {this.state.strings[this.state.country].stepPriorities.text.map((value, i) => {
            return <p key={i} dangerouslySetInnerHTML={{ __html: value }} />;
          })}
        </div>

        <ReactSortable
          group="motivators"
          list={this.state.list}
          setList={this.setList}
          ghostClass="ghost"
          dragClass="drag"
          chosenClass="chosen"
          className="motivators-list"
        >
          {this.state.list.map((item, index) => (
            <span key={index} className="item">
              {item.title}
            </span>
          ))}
        </ReactSortable>
        {this.state.strings[this.state.country].stepPriorities.sortable.map(
          (value, i) => {
            return (
              <p
                className="mt-4"
                key={i}
                dangerouslySetInnerHTML={{ __html: value }}
              />
            );
          }
        )}
        <ReactSortable
          group="motivators"
          list={this.state.result}
          setList={this.setResult}
          ghostClass="ghost"
          dragClass="drag"
          chosenClass="chosen"
          className="motivators-sorted"
        >
          {this.state.result.map((item, index) => (
            <div key={item.id} className="card">
              <div className="card-title">
                <h3>
                  {index + 1}º {item.title}
                </h3>
              </div>
              <div className="card-content">
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </ReactSortable>

        {this.setNavigation()}
      </div>
    );
  }
}
