export const MAIL_DOMAIN = ".next@bbva.com";

export const DAYSOFWEEK = ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"];

export const AGE = {
  ES: [
    { id: 201, title: "hasta 25 años" },
    { id: 202, title: "de 25 a 29 años" },
    { id: 203, title: "de 30 a 38 años" },
    { id: 204, title: "de 39 a 45 años" },
    { id: 205, title: "más de 45 años" },
    { id: -1, title: "Prefiero no contestar" },
  ]
};

export const PLANETS = {
  ES: [
    { id: 201, title: "Agile Practitioner" },
    { id: 203, title: "Communications" },
    { id: 204, title: "Consulting" },
    { id: 205, title: "Data Scientist" },
    { id: 206, title: "Finance" },
    { id: 207, title: "Functional Analyst" },
    { id: 208, title: "Infrastructure Specialist" },
    { id: 209, title: "People" },
    { id: 210, title: "Product Owner" },
    { id: 211, title: "QA" },
    { id: 212, title: "Research" },
    { id: 213, title: "Security Engineer" },
    { id: 214, title: "Software Crafter" },
    { id: 215, title: "Specialist" },
    { id: 216, title: "Devops" },
    { id: 217, title: "Team Lead" },
    { id: 219, title: "UX" },
    { id: 221, title: "Networking" },
    { id: 222, title: "Solution Architect" },
    { id: 223, title: "SRE" },
    { id: 224, title: "Operaciones" },
    { id: 225, title: "Delivery Manager" },
    { id: 226, title: "Academy" },
    { id: -1, title: "Prefiero no contestar" },
  ]
};

export const LEVELS = {
  ES: [
    { id: 101, title: "Nivel 1" },
    { id: 102, title: "Nivel 2" },
    { id: 103, title: "Nivel 3" },
    { id: 104, title: "Nivel 4" },
    { id: 105, title: "Nivel 5" },
    { id: -1, title: "Prefiero no contestar" },
  ]
};

export const GENRES = {
  ES: [
    { id: 101, title: "Masculino" },
    { id: 102, title: "Femenino" },
    { id: 103, title: "Otros" },
    { id: -1, title: "Prefiero no contestar" },
  ]
};

export const WORKPLACES = {
  ES: [
    { id: 108, title: "Bilbao" },
    { id: 105, title: "Ciudad BBVA, Zelandia" },
    { id: 101, title: "Manoteras" },
    { id: 107, title: "Tablas I" },
    { id: 104, title: "Tablas III" },
    { id: 111, title: "Tres Cantos" },
    { id: 106, title: "Otros en Madrid (Recoletos, Isla Sicilia, Cliente ext., etc)" },
  ]
};

export const ASCRIPTIONPLACES = {
  ES: [
    { id: 101, title: "Madrid" },
    { id: 102, title: "Bilbao" },
  ]
};

export const WORKLENGTHS = {
  ES: [
    { id: 11, title: "0 a 2 años" },
    { id: 12, title: "2 a 5 años" },
    { id: 13, title: "5 a 10 años" },
    { id: 14, title: "Más de 10 años" },
  ]
};

export const DEPRECATED_PROJECTS = {
  ES: [
    {
      id: 101,
      title: "ANS",
      children: [
        { id: 10110, title: "ANS" }
      ],
    },
    {
      id: 102,
      title: "Architecture",
      children: [
        { id: 10210, title: "Channels, BUEAS, Labs, Global Deploy., DAAS" },
        { id: 10221, title: "IaaS & Banking Arch-Banking Architecture" },
        { id: 10222, title: "IaaS & Banking Arch-Batch Architectures & Databases" },
        { id: 10223, title: "IaaS & Banking Arch-Compute, Container Platform" },
        { id: 10224, title: "IaaS & Banking Arch-DataX, DataBus" },
        { id: 10225, title: "IaaS & Banking Arch-Mainframe Arch. & Modern., Storage, Networking, Tech. Deploy." },
        { id: 10226, title: "IaaS & Banking Arch-Services Architecture" },
        { id: 10230, title: "PSAM" },
        { id: 10240, title: "Public Cloud" },
        { id: 10261, title: "SECaaS-Core Security" },
        { id: 10262, title: "SECaaS-Enterprise Security" },
        { id: 10263, title: "SECaaS-Extended Services + SeP" },
        { id: 10264, title: "SECaaS-Horizon - Architecture" }
      ],
    },
    {
      id: 103,
      title: "CFE",
      children: [
        { id: 10310, title: "Arquitectura" },
        { id: 10320, title: "CS&ER, GAE, T&C, R&F" }
      ],
    },
    {
      id: 104,
      title: "CIB",
      children: [
        { id: 10410, title: "CIB Engineering" }
      ],
    },
    {
      id: 105,
      title: "Client Solutions",
      children: [
        { id: 10510, title: "AM & Global Wealth" },
        { id: 10520, title: "Arquitectura & Security Client Solutions" },
        { id: 10530, title: "Enterprise Clients-Enterprise Clients" },
        { id: 10540, title: "Enterprise Clients-Horizon- Enterprise CS" },
        { id: 10550, title: "Retail Client Solutions y Design MKT. & Resp Business" }	
      ],
    },
    {
      id: 106,
      title: "Corporate Security & others",
      children: [
        { id: 10610, title: "CS&ER, BPE&O, S&C-Bex" }
      ],
    },
    {
      id: 107,
      title: "Data",
      children: [
        { id: 10710, title: "Data" }
      ],
    },
    {
      id: 108,
      title: "Delivery-Business",
      children: [
        { id: 10810, title: "Tecnología PCE" }
      ],
    },
    {
      id: 109,
      title: "Engineering Spain",
      children: [
        { id: 10910, title: "Arquitectura" },
        { id: 10920, title: "Channels Systems, Philip y procesos" },
        { id: 10930, title: "Enterprise Systems" }
      ],
    },
    {
      id: 110,
      title: "Global Software Development",
      children: [
        { id: 11010, title: "Alpha - Horizon" },
        { id: 11020, title: "SW Development & Transformation" }
      ],
    },
    {
      id: 111,
      title: "Infrastructure & Operations",
      children: [
        { id: 11110, title: "Infrastruct. & Communications" }
      ],
    },
    {
      id: 112,
      title: "Next - Enterprise",
      children: [
        { id: 11210, title: "Talent" },
        { id: 11220, title: "Talent Delivery" },
        { id: 11230, title: "Enterprise Ops" },
        { id: 11240, title: "Enterprise Tech&Data" },
        { id: 11250, title: "Staffing pool (desasignad@s)" },
      ],
    }
  ],
};

export const PROJECTS = {
  ES: [
    {
      id: 108,
      title: "Delivery-Business",
      children: [
        { id: 10810, title: "Delivery-Business" }
      ],
    },
    {
      id: 101,
      title: "ANS",
      children: [
        { id: 10110, title: "ANS" }
      ],
    },
    {
      id: 110,
      title: "Global Software Development",
      children: [
        { id: 11030, title: "Alpha Core Banking" },
        { id: 11031, title: "Developers Communities + W2E SW Dev Journeys + Testing + SW dev Policies & Requirements" },
        { id: 11032, title: "Security GSD + Core Banking SW2" }
      ],
    },
    {
      id: 102,
      title: "Architecture",
      children: [
        { id: 10270, title: "Batch Architectures & Databases" },
        { id: 10271, title: "PaaS Architecture + DataBus + Mainframe Arch. & Modernization" },
        { id: 10272, title: "Services Architecture" },
        { id: 10273, title: "Banking Architecture" },
        { id: 10274, title: "Data Technical Governance + DataX + DatioEvolution" },
        { id: 10275, title: "Middleware BPM + Middleware Gestión Documental" },
        { id: 10276, title: "Services + Cells + Labs & Blockchain" },
        { id: 10277, title: "Automation Deployment & Infrastructurure + Extensions & Reliability" },
        { id: 10278, title: "Monitoring, Observability & Telemetry" },
        { id: 10279, title: "CCoE GCP + CCoE AWS" },
        { id: 10280, title: "Banking Architecture Security" },
        { id: 10281, title: "Identity" },
        { id: 10282, title: "Platform Protection + Edge Security" },
        { id: 10283, title: "Extended Services + SeP" }
      ],
    },
    {
      id: 104,
      title: "CIB",
      children: [
        { id: 10411, title: "A&I CIB" },
        { id: 10412, title: "SW Delivery CIB" },
        { id: 10413, title: "SW Cross CIB" },
        { id: 10414, title: "ER&CA CIB" }
      ],
    },
    {
      id: 107,
      title: "Data engineering + AI Factory",
      children: [
        { id: 10710, title: "Data engineering + AI Factory" }
      ],
    },
    {
      id: 111,
      title: "Infrastructure & Operations",
      children: [
        { id: 11111, title: "IT Operations + Optimization & Transformation" },
        { id: 11112, title: "Compute" },
        { id: 11113, title: "Networking + Storage" },
        { id: 11114, title: "Networking & Workplace" },
        { id: 11115, title: "Processes & DevOps + Service & Information Focal Point" }
      ],
    },
    {
      id: 103,
      title: "CFE",
      children: [
        { id: 10321, title: "Architecture & Infrastructure & Corporate Security" },
        { id: 10322, title: "T&C" },
        { id: 10323, title: "GRM" },
        { id: 10324, title: "R&IC + Aud. Legal & Chairman Units" },
        { id: 10325, title: "Finance" }
      ],
    },
    {
      id: 109,
      title: "Engineering Spain",
      children: [
        { id: 10910, title: "Arquitectura" },
        { id: 10920, title: "Channels Systems" },
        { id: 10930, title: "Enterprise Systems + Enterprise Systems UX" },
        { id: 10931, title: "Asset Management SD" }
      ],
    },
    {
      id: 105,
      title: "Client Solutions Engineering",
      children: [
        { id: 10551, title: "Software Development Delivery Enterprise" },
        { id: 10552, title: "Software Development Delivery Retail" },
        { id: 10553, title: "Software Development Architecture & Security + Software Development Delivery Cross + Corporate Security" }
      ],
    },
    {
      id: 112,
      title: "Next - Enterprise",
      children: [
        { id: 11210, title: "Talent" },
        { id: 11220, title: "Talent Delivery" },
        { id: 11230, title: "Enterprise Ops" },
        { id: 11240, title: "Enterprise Tech&Data" },
      ],
    },
    {
      id: 113,
      title: "Desasignados",
      children: [
        { id: 11300, title: "Desasignados" },
      ],
    },
    {
      id: 114,
      title: "Otros",
      children: [
        { id: 11400, title: "Otros" },
      ],
    }    
  ],
};

export const YESNO = [
  { id: 1, title: "No" },
  { id: 2, title: "Sí" },
];

export const MOTIVATORS_LIST = [
  {
    id: 1,
    title: "Autonomía",
    description:
      "Independencia en mi trabajo, libertad en la toma de decisiones, control sobre mis tareas, sentir que se confía en mí",
  },
  {
    id: 2,
    title: "Influencia",
    description:
      "Respeto a mis opiniones, participar en la toma de decisiones y en la forma de pensar del resto, contribuir al éxito del proyecto",
  },
  {
    id: 3,
    title: "Ambiente de trabajo / Compañerismo",
    description:
      "Encaje con el equipo, buen rollo dentro y fuera del proyecto, ayuda del resto cuando se necesita, calidad humana",
  },
  {
    id: 4,
    title: "Retribución",
    description: "Retribución flexible, salario, beneficios sociales",
  },
  {
    id: 5,
    title: "Posición",
    description: "Visibilidad dentro del proyecto/área, interlocución, estatus",
  },
  {
    id: 6,
    title: "Reconocimiento",
    description:
      "Sentir que se me valora como persona y profesional en mi día a día en el proyecto y en Next",
  },
  {
    id: 7,
    title: "Aprendizaje / Curiosidad",
    description:
      "Aprendo cosas nuevas de mis compañeros, posibilidad de poner en práctica lo que ya conozco y me interesa",
  },
  {
    id: 8,
    title: "Retos",
    description:
      "Proyectos adecuados a mis intereses, conocimientos y tecnologías retadoras",
  },
  {
    id: 9,
    title: "Tiempo",
    description:
      "Horario flexible, teletrabajo, conciliación, commuting, tiempo para cuando lo necesito",
  },
  {
    id: 10,
    title: "Espacio de trabajo / Equipamiento",
    description:
      "Espacio de trabajo en casa, adecuación de las oficinas, commuting, equipamiento",
  },
  {
    id: 11,
    title: "Estabilidad",
    description:
      "Orden, entorno estable, reglas claras, dirección compañía clara",
  },
  {
    id: 12,
    title: "Valores / Propósito",
    description:
      "Tiene que ver conmigo, orgullo de pertenencia, propósito de compañía adecuado, ilusión, visión estratégica retadora",
  },
];

export const MOTIVATORS_LEAVE_LIST = [
  {
    id: 1,
    title: "Falta de autonomía",
    description:
      "No tengo la capacidad de tomar decisiones sobre mi trabajo y la forma de realizarlo",
  },
  {
    id: 21,
    title: "Problemas con los stakeholders del proyecto",
    description:
      "Falta de entendimiento con mi cliente y/o stakeholder. Poca influencia en mi labor y baja consideración de mi trabajo",
  },
  {
    id: 22,
    title: "Problemas con superiores/responsables de Next",
    description:
      "Falta de entendimiento con mis superiores/responsables de Next",
  },
  {
    id: 3,
    title: "No encajo en la Compañía (valores, personas, etc.)",
    description:
      "No estoy cómodo con mis compañeros y/o los valores que representa Next",
  },
  {
    id: 4,
    title: "Falta de reconocimiento profesional",
    description:
      "No recibo el feedback y valoración que espero de compañeros y/o stakeholders",
  },
  {
    id: 5,
    title: "Proyectos poco retadores",
    description:
      "Los proyectos a los que tengo acceso son de escaso interés profesional para mi. Riesgo de quedarme estancado profesionalmente",
  },
  {
    id: 6,
    title: "Poco aprendizaje",
    description:
      "Mi situación actual me impide adquirir conocimientos nuevos e interesantes para mi futuro profesional",
  },
  {
    id: 7,
    title: "Condiciones económicas",
    description:
      "Mis condiciones salariales actuales están por debajo de las condiciones de mercado para posiciones similares a la mía",
  },
  {
    id: 8,
    title: "Tiempo y conciliación",
    description:
      "Imposibilidad de disponer de tiempo o flexibilidad cuando lo necesito. No puedo disfrutar de las opciones en cuanto a horarios, teletrabajo, etc. que Next ofrece",
  },
  {
    id: 9,
    title: "Distancia centro de trabajo",
    description:
      "Los desplazamientos al centro de trabajo me suponen un consumo diario de tiempo que no puedo asumir",
  },
  {
    id: 10,
    title: "Rol en la compañía",
    description:
      "La posición que ostento en la Compañía está por debajo de mis posibilidades",
  },
  {
    id: 11,
    title: "Falta estabilidad",
    description:
      "Demasiados cambios en la compañía, reglas no claras y dirección errática",
  },
];

export const COUNTRY = [
  { id: "ES", title: "España" },
  { id: "MX", title: "México" },
];

export const YESNONSNC = [
  { id: 1, title: "No" },
  { id: 2, title: "Sí" },
  { id: 3, title: "No lo sé" },
];

export const COMPANY = [{ id: "bbva_next", title: "BBVA Next" }];

export const DISC_QUESTIONS = [
  {
    id: "disc-d-atrevido-prudente",
    min: 0,
    max: 3,
    maxLabel: "Atrevido/a",
    minLabel: "Prudente",
    type: "D",
  },
  {
    id: "disc-i-persuasivo-cohibido",
    min: 0,
    max: 3,
    maxLabel: "Persuasivo/a",
    minLabel: "Cohibido/a",
    type: "I",
  },
  {
    id: "disc-s-rutina-cambios",
    min: 0,
    max: 3,
    maxLabel: "Rutina",
    minLabel: "Cambios",
    type: "S",
  },
  {
    id: "disc-c-sistematico-procedimental-independencia-autonomia",
    min: 0,
    max: 3,
    maxLabel: "Sistemático/a / procedimental",
    minLabel: "Independencia / autonomía",
    type: "C",
  },
  {
    id: "disc-d-competitivo-sosegado",
    min: 0,
    max: 3,
    maxLabel: "Competitivo/a",
    minLabel: "Sosegado/a",
    type: "D",
  },
  {
    id: "disc-i-protagonista-timido",
    min: 0,
    max: 3,
    maxLabel: "Protagonista",
    minLabel: "Tímido/a",
    type: "I",
  },
  {
    id: "disc-s-reflexivo-impulsivo",
    min: 0,
    max: 3,
    maxLabel: "Reflexivo/a",
    minLabel: "Impulsivo/a",
    type: "S",
  },
  {
    id: "disc-c-con-guion-sin-guion",
    min: 0,
    max: 3,
    maxLabel: "Con guión",
    minLabel: "Sin guión",
    type: "C",
  },
  {
    id: "disc-d-energico-apacible",
    min: 0,
    max: 3,
    maxLabel: "Enérgico/a",
    minLabel: "Apacible",
    type: "D",
  },
  {
    id: "disc-i-extrovertido-introvertido",
    min: 0,
    max: 3,
    maxLabel: "Extrovertido/a",
    minLabel: "Introvertido/a",
    type: "I",
  },
  {
    id: "disc-s-paciente-impaciente",
    min: 0,
    max: 3,
    maxLabel: "Paciente",
    minLabel: "Impaciente",
    type: "S",
  },
  {
    id: "disc-c-planificacion-certidumbre-improvisacion-riesgos",
    min: 0,
    max: 3,
    maxLabel: "Planificación / certidumbre",
    minLabel: "Improvisación / riesgos",
    type: "C",
  },
];