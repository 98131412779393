import React from "react";
import { returnString } from "../strings";
import thumbs_up from "../../../images/thumbs_up.svg";

export default class Success extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tokenRole: this.props.data.tokenRole,
      employee_name: this.props.data.employee_name,
      strings: null,
      country: this.props.data.country,
    };
  }

  UNSAFE_componentWillMount() {
    let strings = returnString(this.state.position, this.state.applicant);
    this.setState({ strings: strings });
  }

  scrollTop = () => {
    window.scrollTo(0, 0);
  };

  componentDidMount() {
    this.scrollTop();
  }

  render() {
    return (
      <div className="success">
        <div className="mb-4 thanks">
          <div className="m-1 text-center">
            <img src={thumbs_up} className="img" alt="" />
          </div>

          <h2 className="text-bold text-center">
            {this.state.strings[this.state.country].success.h2}
          </h2>
        </div>
      </div>
    );
  }
}
