import React, { Component } from "react";
import axios from "axios";
import qs from "query-string";
import { Route, withRouter } from "react-router-dom";

// Routes
import Home from "../../../routes/recruitingmotivators/Home";
import StepDisc from "../../../routes/recruitingmotivators/StepDisc";
import StepPriorities from "../../../routes/recruitingmotivators/StepPriorities";
import Success from "../../../routes/recruitingmotivators/Success";
import Error from "../../../routes/Error";

// Components
import { GenericModal, LoadingModal } from "../../Modals";
import { properties } from "../../../properties.js";
import { MOTIVATORS_LIST, DISC_QUESTIONS } from "../../../routes/constants";

const { error } = console;

class RecruitingMotivators extends Component {
  constructor(props) {
    super(props);

    const country = this.props.country;
    const campaignId = this.props.campaignId;
    const { tokenId } = qs.parse(this.props.location.search);
    let defaultDiscObj = {};
    DISC_QUESTIONS.forEach(
      (q) =>
        (defaultDiscObj[q.id] = {
          value: q.min,
          type: q.type,
          min: q.min,
          max: q.max,
        })
    );

    this.state = {
      tokenId,
      country,
      campaignId,
      applicant: this.props.data.applicant,
      position: this.props.data.position,
      role: "applicant",

      // Step DISC
      disc: defaultDiscObj,

      // Step Priorities
      alreadyPushedCaptcha: false,
      alreadySorted: false,
      list: [...MOTIVATORS_LIST.map((motivator) => ({ ...motivator }))],

      // Modals
      showLoadingModal: false,
      modal: {
        type: "",
        name: "",
        desc: "",
        show: false,
      },
    };
  }

  /* MODALS */
  showModal = (type, title, desc) => {
    this.setState({
      modal: {
        type,
        name: title,
        desc,
        show: true,
      },
      showLoadingModal: false,
    });
  };

  hideModal = () => {
    this.setState((prevState) => ({
      modal: {
        ...prevState.modal,
        show: false,
      },
      showLoadingModal: false,
    }));
  };

  toggleLoading = (status) => {
    this.setState({ showLoadingModal: status });
  };
  /* END MODALS */

  onChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  filterResponse = () => {
    return {
      tokenId: this.state.tokenId,
      country: this.state.country,
      campaignId: this.state.campaignId,
      applicant: this.state.applicant,
      position: this.state.position,
      role: this.state.role,

      // Step DISC
      disc: this.state.disc,

      // Step Priorities
      list: this.state.list,
    };
  };

  onSubmit = async () => {
    const data = this.filterResponse();

    try {
      this.toggleLoading(true);

      await axios.post(properties.host + `responses`, data);

      localStorage.clear();

      this.toggleLoading(false);

      // Redirect to OK page
      this.props.history.push(
        `/survey/success?tokenId=${this.state.tokenId}&campaign_id=${this.state.campaignId}`
      );
    } catch (e) {
      error(e);
      this.showModal("error", "Error", "No se pudo enviar el cuestionario");
    }
  };

  render() {
    return (
      <>
        <LoadingModal show={this.state.showLoadingModal} />

        <GenericModal
          type={this.state.modal.type}
          name={this.state.modal.name}
          desc={this.state.modal.desc}
          show={this.state.modal.show}
          callback={() => this.hideModal()}
        />

        <Route exact path="/survey">
          <Home data={this.state} />
        </Route>

        <Route path="/survey/step-disc">
          <StepDisc data={this.state} onChange={this.onChange} />
        </Route>

        <Route path="/survey/step-priorities">
          <StepPriorities
            data={this.state}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
          />
        </Route>

        <Route exact path="/survey/success">
          <Success data={this.state} />
        </Route>

        <Route exact path="/survey/error">
          <Error redirect="/step-priorities" />
        </Route>
      </>
    );
  }
}

export default withRouter(RecruitingMotivators);
