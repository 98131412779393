import React, { Component } from "react";
import Navigation from "../../../components/Navigation";
import SelectOption from "../../../components/SelectOption";
import { returnString } from "../strings";
import Rating from 'react-rating';
import enps from "../../../images/enps.png";
import {
  AGE,
  GENRES,
  PLANETS,
  LEVELS,
  WORKPLACES,
  ASCRIPTIONPLACES,
  WORKLENGTHS,
  PROJECTS,
  YESNO,
} from "../../constants";
// import Slider from "rc-slider";
import "rc-slider/assets/index.css";

var stepDemographics = {};
var subareaProjects = [];

export default class StepDemographics extends Component {
  constructor(props) {
    super(props);

    let fieldNames = [
      "age",
      "genre",
      "planet",
      "level",
      "workplace",
      "ascriptionplace",
      "worklength",
      // "project_level_1",
      "project_level_2",
      // "workinternational",
      "cd",
      "enps"
    ];

    this.state = {
      requiredFields: fieldNames,
      dirtyFields: this.props.data.alreadyIdentified ? fieldNames : [],
      back_path: "/survey",
      next_path: "/survey/step-priorities",
      // next_path: "/survey/step-disc",
      country: this.props.data.country,
      tokenType: this.props.data.tokenType,
      strings: null,
      context: this.props.data.context,
    };
  }

  UNSAFE_componentWillMount() {
    let strings = returnString(this.state.tokenType, this.state.context);

    this.setState({
      strings: strings,
    });
  }

  getSubareaProjects = (value) => {
    const level1 = parseInt(value);
    const result = PROJECTS[this.state.country].find((p) => p.id === level1);
    return result.children;
  };

  changeValue = (event) => {
    const { name, value } = event.target;

    let dirtyFields = this.state.dirtyFields.slice();
    let index = dirtyFields.indexOf(name);

    if (value !== "") {
      this.props.onChange(name, value);
      stepDemographics[name] = value;

      if (dirtyFields.indexOf(name) === -1) {
        dirtyFields.push(name);
      }
    } else {
      if (index !== -1) {
        dirtyFields.splice(index, 1);
      }
    }

    let completed = dirtyFields.length === this.state.requiredFields.length;
    this.props.onChange("alreadyIdentified", completed);
    this.setState({ dirtyFields });

    if (completed) {
      localStorage.setItem("stepDemographics", JSON.stringify(stepDemographics));
    }

    if (name === "project_level_1") {
      subareaProjects = this.getSubareaProjects(value);
      if (subareaProjects.length === 1) {
        this.changeValue({
          target: { name: "project_level_2", value: subareaProjects[0].id.toString() },
        });
      } else {
        this.changeValue({ target: { name: "project_level_2", value: "" } });
      }
    }
  };

  changeProp = (value, name) => {
    // this.props.onChange(name, val);
    this.changeValue({target: {
      name, 
      value
    }})
  };

  setNavigation() {
    if (this.props.data.alreadyIdentified && this.props.data.enps !== 0) {
      return (
        <Navigation back={this.state.back_path} next={this.state.next_path} />
      );
    } else {
      return <Navigation back={this.state.back_path} />;
    }
  }

  render() {
    return (
      <div className="step-demographics">
        <h2 className="mb-3 text-bold">
          {this.state.strings[
            this.state.country
          ].stepFinal.enps.title.map((value, i) => {
            return (
              <span key={i} dangerouslySetInnerHTML={{ __html: value }} />
            );
          })}
        </h2>
        
        <div className="bg-grey pb-1">
          <div className="select-container">
            <div className="block">
              <div>
                {this.state.strings[
                  this.state.country
                ].stepFinal.enps.intro.map((value, i) => {
                  return (
                    <p key={i} dangerouslySetInnerHTML={{ __html: value }} />
                  );
                })}
              </div>
              <div className="p-2 text-center">
                <img src={enps} style={{ "width": "100%", "maxWidth": "520px" }} alt="" />
              </div>
              <h4 className="text-bold text-center">
                {this.state.strings[
                  this.state.country
                ].stepFinal.enps.question.map((value, i) => {
                  return (
                    <span key={i} dangerouslySetInnerHTML={{ __html: value }} />
                  );
                })}
              </h4>

              <div className="text-center mt-2 enps-container">
                <div>
                  <Rating 
                    start={0} 
                    stop={10}
                    step={1}
                    emptySymbol={[
                      'icon-sad enps-icon enps-icon--low',
                      'icon-sad enps-icon enps-icon--low',
                      'icon-sad enps-icon enps-icon--low', 
                      'icon-sad enps-icon enps-icon--low',
                      'icon-sad enps-icon enps-icon--low',
                      'icon-sad enps-icon enps-icon--low',
                      'icon-neutral enps-icon enps-icon--medium',
                      'icon-neutral enps-icon enps-icon--medium',
                      'icon-smile enps-icon enps-icon--high',
                      'icon-smile enps-icon enps-icon--high']}
                    fullSymbol={[
                      'icon-sad2 enps-icon enps-icon--low',
                      'icon-sad2 enps-icon enps-icon--low',
                      'icon-sad2 enps-icon enps-icon--low', 
                      'icon-sad2 enps-icon enps-icon--low',
                      'icon-sad2 enps-icon enps-icon--low',
                      'icon-sad2 enps-icon enps-icon--low',
                      'icon-neutral2 enps-icon enps-icon--medium',
                      'icon-neutral2 enps-icon enps-icon--medium',
                      'icon-smile2 enps-icon enps-icon--high',
                      'icon-smile2 enps-icon enps-icon--high']}
                    initialRating={this.props.data.enps}
                    onChange={(value) => this.changeProp(value, 'enps')}
                  />
                </div>
                <div className="text-center mt-2">
                  <h3 className="text-bold">
                    {this.props.data.enps ? this.props.data.enps + ' / 10' : ''}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mb-3 mt-3">
          <h2 className="mb-3 text-bold">
            {this.state.strings[this.state.country].stepDemographics.h2}
          </h2>
        </div>

        <div className="bg-grey">
          <p className="mb-4">
            {this.state.strings[this.state.country].stepDemographics.text.map(
              (value, i) => {
                return <span key={i} dangerouslySetInnerHTML={{ __html: value }} />;
              }
            )}
          </p>
          <div className="select-container">
            <div className="select-item text-bold">
              <SelectOption
                label={
                  this.state.strings[this.state.country].stepDemographics
                    .selects.edad
                }
                name="age"
                onChange={this.changeValue}
                options={AGE[this.state.country]}
                defaultValue={this.props.data.age}
                required={true}
              />
            </div>
          </div>

          <div className="select-container">
            <div className="select-item text-bold">
              <SelectOption
                label={
                  this.state.strings[this.state.country].stepDemographics
                    .selects.genero
                }
                name="genre"
                onChange={this.changeValue}
                options={GENRES[this.state.country]}
                defaultValue={this.props.data.genre}
                required={true}
              />
            </div>
          </div>

          <div className="select-container">
            <div className="select-item">
              <SelectOption
                label={
                  this.state.strings[this.state.country].stepDemographics
                    .selects.planeta
                }
                name="planet"
                onChange={this.changeValue}
                options={PLANETS[this.state.country]}
                defaultValue={this.props.data.planet}
                required={true}
              />
            </div>
          </div>

          <div className="select-container">
            <div className="select-item">
              <SelectOption
                label={
                  this.state.strings[this.state.country].stepDemographics
                    .selects.nivel
                }
                name="level"
                onChange={this.changeValue}
                options={LEVELS[this.state.country]}
                defaultValue={this.props.data.level}
              />
            </div>
          </div>

          <div className="select-container">
            <div className="select-item">
              <SelectOption
                label={
                  this.state.strings[this.state.country].stepDemographics
                    .selects.workplace
                }
                name="workplace"
                onChange={this.changeValue}
                options={WORKPLACES[this.state.country]}
                defaultValue={this.props.data.workplace}
              />
            </div>
          </div>

          <div className="select-container">
            <div className="select-item">
              <SelectOption
                label={
                  this.state.strings[this.state.country].stepDemographics
                    .selects.ascriptionplace
                }
                name="ascriptionplace"
                onChange={this.changeValue}
                options={ASCRIPTIONPLACES[this.state.country]}
                defaultValue={this.props.data.ascriptionplace}
              />
            </div>
          </div>

          <div className="select-container">
            <div className="select-item">
              <SelectOption
                label={
                  this.state.strings[this.state.country].stepDemographics
                    .selects.worklength
                }
                name="worklength"
                onChange={this.changeValue}
                options={WORKLENGTHS[this.state.country]}
                defaultValue={this.props.data.worklength}
              />
            </div>
          </div>

          {
            this.state.country === "ES" && (
              <div>
                <div className="select-container">
                  <div className="select-item">
                    <SelectOption
                      label={
                        this.state.strings[this.state.country].stepDemographics
                          .selects.project_level_1
                      }
                      name="project_level_1"
                      onChange={this.changeValue}
                      options={PROJECTS[this.state.country]}
                    />
                  </div>
                </div>

                {this.props.data.project_level_1 !== null &&
                  subareaProjects.length > 1 && (
                    <div className="select-container">
                      <div className="select-item">
                        <SelectOption
                          label={
                            this.state.strings[this.state.country]
                              .stepDemographics.selects.project_level_2
                          }
                          name="project_level_2"
                          onChange={this.changeValue}
                          options={subareaProjects}
                        />
                      </div>
                    </div>
                  )}
              </div>
            )}

          <div className="select-container">
            <div className="select-item">
              <SelectOption
                label={this.state.strings[this.state.country].stepDemographics.selects.cd}
                name="cd"
                onChange={this.changeValue}
                options={YESNO}
                defaultValue={this.props.data.cd}
              />
            </div>
          </div>
        </div>

        {this.setNavigation()}
      </div>
    );
  }
}
