import React, { Component } from "react";
import axios from "axios";
import qs from "query-string";
import { Route, withRouter } from "react-router-dom";

// DataWrappers
import NextMotivators from "./nextmotivators";
import RecruitingMotivators from "./recruitingmotivators";

// components
import Header from "../Header";

// routes
import Error403 from "../../routes/Error403";

// properties
import { properties } from "../../properties.js";

class DataWrapper extends Component {
  constructor(props) {
    super(props);

    const { tokenId, campaign_id } = qs.parse(this.props.location.search);

    this.state = {
      tokenId,
      campaignId: campaign_id,
      tokenInfo: null,
      tokenStatus: null,
      tokenType: null,
      tokenRole: null,
      country: "",
      position: null,
      applicant: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  async getData() {
    try {
      const res = axios.get(
        properties.host +
          `targets?token=${this.state.tokenId}&campaign_id=${this.state.campaignId}`
      );
      const { data } = await res;

      this.setState({
        tokenStatus: data.status,
        tokenType: data.type,
        tokenRole: data.role,
        country: data.country,
        campaignId: data.campaign_id,
        employee_name: data.leaving_employee_name,
        context: data.context,
        position: data.position,
        applicant: data.name,
        email: data.email,
        vacancy: data.vacancy
      });
    } catch (error) {}
  }

  render() {
    return (
      <>
        <Header
          tokenId={this.state.tokenId}
          tokenType={this.state.tokenType}
          tokenRole={this.state.tokenRole}
          position={this.state.position}
          applicant={this.state.applicant}
          location={this.props.location.pathname}
          campaignId={this.state.campaignId}
        />

        <div className="main-container">
          {this.state.tokenStatus !== null ? (
            this.state.tokenStatus === 0 ? (
              this.state.tokenType === "regular" ? (
                <NextMotivators
                  data={this.state}
                  onchange={this.onChange}
                  tokenType={this.state.tokenType}
                  email={this.state.email}
                  country={this.state.country}
                  campaignId={this.state.campaignId}
                />
              ) : this.state.tokenType === "recruiting" ? (
                  <RecruitingMotivators
                    data={this.state}
                    onchange={this.onChange}
                    position={this.state.position}
                    applicant={this.state.applicant}
                    country={this.state.country}
                    campaignId={this.state.campaignId}
                  />
              ) : (
                <Route path="/" component={Error403} />
              )
            ) : (
              <Route path="/" component={Error403} />
            )
          ) : (
            <div className="loader"></div>
          )}
        </div>
        <footer className="app-footer">
          <p>
            Escríbenos{" "}
            <a href="mailto:people-analytics.next.group@bbva.com">aquí</a> para
            cualquier duda o sugerencia
          </p>
        </footer>
      </>
    );
  }
}

export default withRouter(DataWrapper);
