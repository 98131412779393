import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo.png';
import getLocationDescriptor from '../../helpers/get-location-descriptor';


export default function({ tokenId, tokenType, tokenRole, location, campaignId }) {
	const rootpath = location.substr(1).split('/')[0];

	return (
		<header className="App-header">
		<div className="main-container">

			<h1 className="">
			<Link to={getLocationDescriptor(`/${rootpath}`, tokenId, campaignId)}>
				<img src={logo} className="App-logo" alt="logo" />
			</Link>
			{
				tokenType === 'regular' ? ('Next Motivators'):
				tokenType === 'recruiting' ? ('Talent Magnets Motivators'):
				('Motivators')
			}
			</h1>
			</div>
		</header>
	);
}
