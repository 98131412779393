import React from 'react';

export const TextModal = ({ handleClose, show, text }) => {
  const showHideClassName = show ? "modal show-modal" : "modal hide-modal";
  return (
    <div className={showHideClassName}>
      <div className="modal-main">
        <p>{text}</p>
        <button className="btn btn-primary" onClick={handleClose}>Aceptar</button>
      </div>
    </div>
  );
};

export const LoadingModal = ({ show }) => {
  const showHideClassName = show ? "modal show-modal" : "modal hide-modal";
  return (
    <div className={showHideClassName}>
      <div className="modal-main modal-loading no-padding">
        <div className="loader"></div>
      </div>
    </div>
  );
};

export const GenericModal = ({ type, name, desc, callback, show }) => {
  const showHideClassName = show ? "modal show-modal" : "modal hide-modal";
  return (
    <div className={showHideClassName}>
      <div className="modal-main">
        <p className="title">
          {type === "success" ? (
            <i className="icon-checkmark" />
          ) : type === "error" ? (
            <i className="icon-cross" />
          ) : (
            type === "info" && <i className="icon-info" />
          )}
          {name}
        </p>
        <p className="description">{desc}</p>
        <button className="btn btn-primary" onClick={callback}>
          Aceptar
        </button>
      </div>
    </div>
  );
};
