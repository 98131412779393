import React, { Component } from "react";
import { ReactSortable } from "react-sortablejs";
import Navigation from "../../../components/Navigation";
import ReCAPTCHA from "react-google-recaptcha";
import { properties } from "../../../properties.js";
import { returnString } from "../strings";
const recaptchaRef = React.createRef();

export default class StepPriorities extends Component {
  constructor(props) {
    super(props);

    let dirty = this.props.data.alreadySorted;

    this.state = {
      tokenRole: this.props.data.tokenRole,
      employee_name: this.props.data.employee_name,
      strings: null,
      country: this.props.data.country,
      back_path: "",
      next_path: "",
      sorted: 0,
      total: 0 + this.props.data.list.length,
      list: dirty ? [] : this.props.data.list.slice(),
      result: dirty ? this.props.data.list.slice() : [],
    };
  }

  UNSAFE_componentWillMount() {
    let strings = returnString(this.state.position, this.state.applicant);
    const back_path = "/survey/step-disc";
    const next_path = "/survey/success";

    this.setState({
      strings,
      back_path,
      next_path,
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    if (this.state.result.length === this.state.total) {
      this.props.onChange("alreadySorted", true);
      this.props.onChange("list", this.state.result);
      localStorage.setItem("stepPriorities", JSON.stringify(this.state.result));
    }
  }

  setList = (newList) => {
    this.setState({ list: newList });
  };

  setResult = (newResult) => {
    this.setState({
      result: newResult,
      sorted: newResult.length,
    });

    // force updating lists
    this.componentWillUnmount();
  };

  captchaChange = (value) => {
    this.setState({ value });
    if (value === null) this.setState({ expired: "true" });
    this.props.onChange("alreadyPushedCaptcha", true);
  };

  setNavigation() {
    if (
      this.state.result.length === this.state.total &&
      this.props.data.alreadyPushedCaptcha
    ) {
      return (
        <Navigation
          back={this.state.back_path}
          finish={
            <button
              className="btn btn-primary finish-button"
              onClick={this.onSubmit}
            >
              {this.state.strings[this.state.country].stepPriorities.btn_enviar}
              &nbsp;&nbsp;<i className="icon-play2"></i>
            </button>
          }
        />
      );
    } else {
      return <Navigation back={this.state.back_path} />;
    }
  }

  onSubmit = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    this.props.onSubmit(recaptchaValue);
  };

  render() {
    return (
      <div className="step-priorities">
        <div className="mb-2">
          <h2 className="mb-3 text-bold">
            {this.state.strings[this.state.country].stepPriorities.h2}
          </h2>
          {this.state.strings[this.state.country].stepPriorities.text.map(
            (value, i) => {
              return <p key={i} dangerouslySetInnerHTML={{ __html: value }} />;
            }
          )}
        </div>

        <ReactSortable
          group="motivators"
          list={this.state.list}
          setList={this.setList}
          ghostClass="ghost"
          dragClass="drag"
          chosenClass="chosen"
          className="motivators-list"
        >
          {this.state.list.map((item, index) => (
            <span key={index} className="item">
              {item.title}
            </span>
          ))}
        </ReactSortable>

        <p
          className="mt-4"
          dangerouslySetInnerHTML={{
            __html: this.state.strings[this.state.country].stepPriorities
              .sortable_text,
          }}
        />

        <ReactSortable
          group="motivators"
          list={this.state.result}
          setList={this.setResult}
          ghostClass="ghost"
          dragClass="drag"
          chosenClass="chosen"
          className="motivators-sorted"
        >
          {this.state.result.map((item, index) => (
            <div key={item.id} className="card">
              <div className="card-title">
                <h3>
                  {index + 1}º {item.title}
                </h3>
              </div>
              <div className="card-content">
                <p>{item.description}</p>
              </div>
            </div>
          ))}
        </ReactSortable>

        {this.state.result.length === this.state.total && (
          <div className="clear text-center mt-3 mb-3">
            <ReCAPTCHA
              className="captcha-center"
              ref={recaptchaRef}
              sitekey={properties.idCaptcha}
              onChange={this.captchaChange}
            />
          </div>
        )}

        {this.setNavigation()}
      </div>
    );
  }
}
