import React, { Component } from "react";
import { ReactSortable } from "react-sortablejs";
import Navigation from "../../../components/Navigation";
import { returnString } from "../strings";

export default class StepInfluence extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: this.props.data.list.slice(),
      project_list: [],
      next_list: [],
      back_path: "/survey/step-ratings",
      next_path: "/survey/step-final",
      country: this.props.data.country,
      tokenType: this.props.data.tokenType,
      context: this.props.data.context,
      strings: null,
    };
  }

  initializeLists() {
    let bothList = [];
    let projectList = [];
    let nextList = [];
    for (let item of this.state.list) {
      switch (item.influence) {
        case "proyecto":
          projectList = [...projectList, item];
          break;
        case "ambos":
          bothList = [...bothList, item];
          break;
        case "next":
          nextList = [...nextList, item];
          break;
        default:
          break;
      }
    }

    this.setState({
      list: bothList,
      project_list: projectList,
      next_list: nextList,
    });
  }

  UNSAFE_componentWillMount() {
    let strings = returnString(this.state.tokenType, this.state.context);

    this.setState({
      strings: strings,
    });
  }

  componentDidMount() {
    if (!!this.state.list[0].influence) {
      this.initializeLists();
    }

    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this.props.onChange("list", this.setInfluence());
  }

  setList = (newList) => {
    this.setState({ list: newList });
  };

  setProjectList = (newProjectList) => {
    this.setState({
      project_list: newProjectList,
    });
  };

  setNextList = (newNextList) => {
    this.setState({
      next_list: newNextList,
    });
  };

  setInfluence = () => {
    let influenced_list = [];
    
    for (let item of this.state.project_list) {
      item.influence = "proyecto";
      influenced_list.push(item);
    }

    for (let item of this.state.list) {
      item.influence = "ambos";
      influenced_list.push(item);
    }

    for (let item of this.state.next_list) {
      item.influence = "next";
      influenced_list.push(item);
    }

    let result = influenced_list.sort(function (a, b) {
      return a.position - b.position;
    });

    localStorage.setItem("stepInfluence", JSON.stringify(result));

    return result;
  };

  setNavigation() {
    return (
      <Navigation back={this.state.back_path} next={this.state.next_path} />
    );
  }

  render() {
    return (
      <div className="step-influence">
        <div className="mb-2">
          <h2 className="mb-3 text-bold">
            {this.state.strings[this.state.country].stepInfluence.title}
          </h2>
          <p>{this.state.strings[this.state.country].stepInfluence.subtitle}</p>
        </div>

        <div className="flexrow-container-influence">
          <div className="influence-box">
            <p className="text-center text-bold block mt-2">
              {
                this.state.strings[this.state.country].stepInfluence.sections
                  .project
              }
            </p>
            <ReactSortable
              group="motivators"
              list={this.state.project_list}
              setList={this.setProjectList}
              ghostClass="ghost"
              dragClass="drag"
              chosenClass="chosen"
              className="motivators-list project-influenced-motivators"
            >
              {this.state.project_list.map((item) => (
                <span key={item.id} className="item">
                  {item.title}
                </span>
              ))}
            </ReactSortable>
          </div>

          <div className="influence-box">
            <p className="text-center text-bold block mt-2">
              {
                this.state.strings[this.state.country].stepInfluence.sections
                  .both
              }
            </p>
            <ReactSortable
              group="motivators"
              list={this.state.list}
              setList={this.setList}
              ghostClass="ghost"
              dragClass="drag"
              chosenClass="chosen"
              className="motivators-list both-influenced-motivators"
            >
              {this.state.list.map((item) => (
                <span key={item.id} className="item">
                  {item.title}
                </span>
              ))}
            </ReactSortable>
          </div>

          <div className="influence-box">
            <p className="text-center text-bold block mt-2">
              {
                this.state.strings[this.state.country].stepInfluence.sections
                  .next
              }
            </p>
            <ReactSortable
              group="motivators"
              list={this.state.next_list}
              setList={this.setNextList}
              ghostClass="ghost"
              dragClass="drag"
              chosenClass="chosen"
              className="motivators-list next-influenced-motivators"
            >
              {this.state.next_list.map((item) => (
                <span key={item.id} className="item">
                  {item.title}
                </span>
              ))}
            </ReactSortable>
          </div>
        </div>

        {this.setNavigation()}
      </div>
    );
  }
}
