import React, { Component } from "react";
import Navigation from "../../../components/Navigation";
import { returnString } from "../strings";
import { DISC_QUESTIONS } from "../../constants";
// import InputRange from "react-input-range";
// import "react-input-range/lib/css/index.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

export default class StepDisc extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tokenRole: this.props.data.tokenRole,
      employee_name: this.props.data.employee_name,
      strings: null,
      country: this.props.data.country,
      back_path: "",
      next_path: "",
      result: [],
    };
  }

  UNSAFE_componentWillMount() {
    let strings = returnString(this.state.position, this.state.applicant);
    const back_path = "/survey";
    const next_path = "/survey/step-priorities";

    this.setState({
      strings,
      back_path,
      next_path,
    });
  }

  getSliderMarks = (questionConf) => {
    let result = {};
    for (let i = questionConf.min; i <= questionConf.max; i++) {
      result[i] = (i === questionConf.min ? questionConf.minLabel : i === questionConf.max ? questionConf.maxLabel : "");
    }
    return result;
  };

  changeValue = (event) => {
    const { name, value } = event.target;
    const objCopy = this.props.data.disc;
    objCopy[name].value = value;
    this.props.onChange("disc", objCopy);
    localStorage.setItem("stepDisc", JSON.stringify(objCopy));
  };

  setNavigation() {
    let completed = true;
    Object.keys(this.props.data.disc).forEach(k => {
      if (completed && (this.props.data.disc[k].value > this.props.data.disc[k].max || this.props.data.disc[k].value < this.props.data.disc[k].min)) {
        completed = false;
      }
    })

    if (completed) {
      return (
        <Navigation back={this.state.back_path} next={this.state.next_path} />
      );
    } else {
      return <Navigation back={this.state.back_path} />;
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="step-1 step-demographics">
        <div className="mb-2">
          <h2 className="mb-3 text-bold">
            {this.state.strings[this.state.country].stepDisc.h2}
          </h2>
          {this.state.strings[this.state.country].stepDisc.text.map(
            (value, i) => {
              return <p key={i} dangerouslySetInnerHTML={{ __html: value }} />;
            }
          )}
        </div>
        <div className="bg-grey text-bold">
          <div className="select-container">
            {DISC_QUESTIONS.map((q) => {
              return (
                <div className="select-item" key={q.id}>
                  <div className="text-center">
                    <div
                      className={`slider inline-block ${
                        this.props.data.disc[q.id].value === -1
                          ? "slider--clean"
                          : ""
                      }`}
                    >
                      <div className="ticks">
                        {Array.from(Array(q.max - q.min + 1).keys(), (i) => {
                          return (
                            <span key={i} className="tick">
                              &nbsp;
                            </span>
                          );
                        })}
                      </div>

                      <Slider
                        min={q.min}
                        max={q.max}
                        marks={this.getSliderMarks(q)}
                        step={1}
                        onChange={(value) =>
                          this.changeValue({
                            target: { name: q.id, value },
                          })
                        }
                        defaultValue={this.props.data.disc[q.id].value}
                      />

                      {/*<InputRange
                        name={q.id}
                        minValue={q.min}
                        maxValue={q.max}
                        formatLabel={(v) =>
                          v === q.min
                            ? q.minLabel
                            : v === q.max
                            ? q.maxLabel
                            : ""
                        }
                        onChange={(value) =>
                          this.changeValue({
                            target: { name: q.id, value },
                          })
                        }
                        value={this.props.data.disc[q.id].value}
                      />*/}

                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {this.setNavigation()}
      </div>
    );
  }
}
