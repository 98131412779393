export const returnString = (
  type,
  context,
  applicantName,
  email,
  vacancyName
) => {
  return NEXT_STRINGS;
};

const GDPR =
  "<b>Nota informativa sobre protección de datos de carácter personal:</b><br>Las respuestas que nos facilites a continuación son, por defecto, anónimas. En caso de que voluntariamente te identifiques antes de enviarlo, la información que nos proporciones será tratada de manera confidencial, única y exclusivamente con la finalidad de poder asesorarte adecuadamente en tu desarrollo profesional. Para ello, dicha información podrá ser compartida internamente solo con las personas y/o funciones de la compañía que puedan asesorarte en dicho desarrollo, pero en ningún caso la información será tratada con fines diferentes a los descritos anteriormente, ni por personas o terceros que no pertenezcan a BBVA Next Technologies.";

const DISC_STEP = {
  h2: "Para calentar",
  text: [
    "¿Quieres conocerte un poco mejor? Te invitamos a que te identifiques con aquel término que mejor te defina.",
    "Por supuesto, te daremos feedback en base a tus respuestas al final de la encuesta.",
  ],
};

const DISC_FEEDBACK = {
  description: "Perfil personal",
  disclaimer:
    "Este feedback es orientativo ya que este tipo de pruebas no son deterministas y los resultados pueden cambiar en función del tiempo y la situación.",
  tips: {
    D: {
      name: "Dominancia",
      low: "Un perfil con D baja se define como acomodaticio y apacible, se muestra como una persona que evita los conflictos y que prefiere que le digan cómo hacer las cosas a ser ella quien dirija. Agradable y gustoso/a de hacer las cosas fáciles a los demás.",
      high: "Un perfil con D alta se define como enérgico y competitivo, directo y asertivo en la manera de expresarse y muy orientado a resultados. Le motiva el poder y la autoridad y tiene miedo a fracasar precisamente por su carácter emprendedor. Su estilo de liderazgo es directivo y autocrático.",
    },
    I: {
      name: "Influencia",
      low: "Un perfil con I baja se define como reservado y cohibido, lo que le muestra cómo una persona introvertida. Ese carácter serio le puede hacer parecer desconfiado/a y reflexivo/a. Es lógico/a, incisivo/a y se siente cómodo/a con los hechos más que con las palabras.",
      high: "Un perfil con I alta se define como influyente, amistoso y muy comunicativo. Le motiva el premio y el reconocimiento social. Positivo/a y persuasivo/a, evita el rechazo social. Su estilo de liderazgo es muy participativo y motivador.",
    },
    S: {
      name: "Estabilidad",
      low: "Un perfil con S baja se define como inquieto y activo, lo que le mantiene siempre alerta. Este ritmo alto le hace mostrarse como alguien inquieto e impulsivo a quien la rutina le puede aburrir. Le gusta el cambio y la novedad y es muy flexible.",
      high: "Un perfil con S alta se define como prudente, atento y amable. Le motiva la seguridad y se muestra atento y confiable ante los demás. Sus temores básicos son el cambio y la inseguridad. Le gusta trabajar en entornos predecibles donde tenga todo controlado y el ritmo del trabajo sea el adecuado a sus características.",
    },
    C: {
      name: "Cumplimiento",
      low: "Un perfil con C baja se define como firme en sus opiniones lo que le puede mostrar como alguien terco, de fuerte voluntad e inflexible. La claridad en sus ideas le hace una persona independiente y constante a la que le gusta hacer las cosas según sus normas.",
      high: "Un perfil con C alta se define como sistemático, preciso y lógico. Le motivan los procesos y la estandarización. Le gusta la exactitud y el perfeccionismo y evita siempre que puede el conflicto. Cuidadoso/a en su trabajo, se maneja bien en el cumplimiento de normas y procesos.",
    },
  },
};

const INFLUENCE = {
  title: "De tu satisfacción en los motivadores, ¿quién influye más?",
  subtitle: "Arrastra cada elemento a la caja correspondiente.",
  sections: {
    project: "Principalmente mi proyecto",
    both: "Ambos",
    next: "Principalmente Next",
  },
};

const CONSENT_LINK = "https://docs.google.com/document/d/14m8WXy9fGGewPC3zD93FyzryhfL8f8nqkFPxM6knQRE/edit?usp=sharing";

const CONSENT = {
  link: 'Acepto las <a href="' + CONSENT_LINK + '" target="new" class="link">condiciones del tratamiento</a> de los datos'
}

const NEXT_STRINGS = {
  icon_grid: [
    /*{
      icon: "icon-calendar",
      key: "calendar",
      texto: "2 veces al año",
    },*/
    {
      icon: "icon-clock",
      key: "duration",
      texto: "Tiempo estimado: 10 minutos",
    },
    {
      icon: "icon-user-check",
      key: "download",
      texto: "Descarga de inmediato tus resultados",
    },
    /*{
      icon: "icon-lock",
      key: "security",
      texto: "Tus respuestas son anónimas",
    },*/
  ],
  ES: {
    home: {
      h2: "Queremos conocerte más para hacer de Next una compañía mejor",
      que_es: {
        h4: "¿Qué es Next Motivators?",
        text: [
          //"Esta iniciativa tiene como objetivo conocer cómo funciona la motivación, cómo está y cómo evoluciona en el conjunto de la Compañía.",
          // "Tus respuestas no serán objeto de ningún tipo de valoración al respecto de tu situación en la Compañía.",
          "Ya está abierta la ventana de participación en la encuesta Next Motivators y queremos recordarte que este es un feedback confidencial que nos servirá para implementar mejoras o lanzar nuevas líneas de trabajo.",
          // "Puedes participar sólo desde el link del email y recuerda que el formulario no se guarda y permite una única respuesta.",
          "En esta encuesta encontrarás los siguientes bloques de preguntas:",
          "<b>1. Datos demográficos</b>, que nos ayudan a encuadrar las respuestas y darles contexto",
          "<b>2. Priorización</b> de bloques de motivadores",
          "<b>3. Satisfacción</b> actual de bloques de motivadores",
          "<b>4. Alineamiento con proyecto</b>",
          "<b>5. Solicitud de tus resultados</b> individuales",
        ],
      },
      disclaimerGDPR: GDPR,
      footer: ["¡Muchas gracias!"],
    },
    stepDemographics: {
      h2: "Para entender mejor los datos",
      text: [
        "Para poder agrupar y analizar las respuestas necesitamos que respondas a las siguientes preguntas:",
      ],
      selects: {
        edad: "¿Cuál es tu rango de edad?",
        genero: "¿Cuál es tu género?",
        planeta: "¿Cuál es tu planeta?",
        nivel: "¿En qué nivel estás?",
        workplace: "¿En qué centro de trabajo ejerces tu labor en función de tu proyecto?",
        ascriptionplace: "¿A qué sede corporativa de Next estás adscrito a nivel administrativo?",
        worklength: "¿Cuánto tiempo llevas en Next?",
        project_level_1: "¿A qué área de proyectos perteneces?",
        project_level_2: "¿A qué subárea de proyectos perteneces?",
        workinternational:
          "¿Tienes que amoldarte constantemente al horario de otras geografías?",
        cd: "¿Tienes el rol de Delivery Associate?",
        cd_past: "¿Eras Delivery Associate?",
      },
    },
    stepDisc: DISC_STEP,
    stepPriorities: {
      h2: "En un trabajo ideal ¿cuáles serían tus prioridades?",
      text: [
        "Mueve los elementos a la caja inferior y ordena de mayor importancia a menor importancia este listado.",
      ],
      sortable: [
        "Arrastra <b>todos</b> los elementos a esta caja para poder continuar.",
      ],
    },
    stepRatings: {
      h2: "¿Actualmente es así en BBVA Next?",
      text: [
        "Rellena las estrellas para indicar el nivel en el que se cumplen cada uno de los elementos en tu día a día.",
      ],
      card_content: {
        text: ["¿En qué medida se cumple en tu trabajo?"],
        tags: ["1.Muy poco", "10.Mucho"],
        textarea: ["¿Por qué crees que es así?"],
      },
    },
    stepInfluence: INFLUENCE,
    stepFinal: {
      h2: "Envia tus respuestas y consulta los resultados",
      placeholders: [
        "¿Quieres comentar algo?",
        "¿Cuántas veces?",
        "nombre.apellido.next@bbva.com (opcional)",
        "nombre.apellido.next@bbva.com",
        "Tu respuesta (opcional)",
        "¿Cómo fue tu paso por Staffing? (opcional)",
      ],
      rating: {
        text: ["¿En qué grado se están cumpliendo tus expectativas en Next?"],
        tags: [
          "No, no se están cumpliendo en absoluto",
          "Sí, se están cumpliendo",
        ],
      },
      enps: {
        title: ["eNPS"],
        intro: [
          "El eNPS (Employee Net Promoter Score) es uno de los principales indicadores mediante el que recogemos tu valoración de Next de forma global. <br></br>",
          "Ten en cuenta que el indicador se valora de la siguiente forma:  eNPS  =  % de positivos  -  % de desfavorables <br></br>"
        ],
        question: ["¿Consideras que BBVA Next es una buena empresa para trabajar?"],
      },
      consent: CONSENT,
      modelo: {
        title: [
          "¿Quieres participar en el modelo?"
        ],
        bloque1: [
          "Te garantizamos que la recopilación de los datos de cada empleado/a participante es anónima y los datos se tratan posteriormente en agregado.",
        ],
        bloque2: [
          "Si voluntariamente quieres que compartamos tu información individual con las figuras internas de Next que pueden asesorarte en tu desarrollo profesional (Delivery Associate, Delivery Manager, etc.), por favor, da tu consentimiento a continuación.",
        ],
        bloque3: [
          'Y, si tienes dudas del alcance del tratamiento de tus datos personales o quieres saber cómo ejercer el desistimiento, sólo tienes que consultar la información <a href="' + CONSENT_LINK + '" target="new" class="link">aquí</a>.'
        ],
      },
      comments: ["¿Te gustaría comentarnos algo?"],
      btn_enviar: "Enviar encuesta",
      userEmailCheck: "¿Es correcto el email?",
    },
    success: {
      h2: {
        bold: ["¡Gracias por participar!"],
        text: ["&nbsp;Aquí tienes tus resultados:"],
      },
      disc: DISC_FEEDBACK,
      chart: {
        description: [
          "Atendiendo a tus respuestas podemos ofrecerte las siguientes hipótesis:",
        ],
        download: "Descargar mis resultados",
      },
      valoracion: {
        description: [
          "¿En qué medida crees que son acertadas estas hipótesis?",
        ],
        valor: ["¿Te aportan valor?"],
        disc: ["¿Y el perfil personal?"],
        enviar: "Enviar valoración",
        enviado: "¡Valoración enviada!",
      },
      placeholders: ["Tus comentarios (opcional)"],
    },
  },
};
