import React from "react";

export default function () {
  return (
    <div className="error-page text-center">
      <p className="m-5">
        <span className="icon-cross text-error f-s-100" />
      </p>
      <p className="f-s-20 text-bold">Acceso no permitido</p>
      <br />
      <p>
        El token de uso único no es válido, ha caducado o ya ha sido utilizado.
      </p>
      <br />
      <p>
        Por favor accede a través del link que figura en tu email original y no
        a través de emails redirigidos. ¡Muchas gracias!
      </p>
    </div>
  );
}
