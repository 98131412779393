import React, { Component } from "react";
import Navigation from "../../../components/Navigation";
import { returnString } from "../strings";
import illustration from "../../../images/intro.jpg";
import chronometer from "../../../images/chronometer.svg";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      strings: null,
      country: this.props.data.country,
      path: "/survey/step-1",
      applicant: this.props.data.applicant,
      position: this.props.data.position,
    };
  }

  UNSAFE_componentWillMount() {
    let strings = returnString(this.state.position, this.state.applicant);
    const path = "/survey/step-disc";

    this.setState({
      strings,
      path,
      navigation: <Navigation next={path} />,
    });
  }

  getImage(key) {
    let selected = null;
    switch (key) {
      case "duration":
        selected = chronometer;
        break;
      default:
        break;
    }
    return <img src={selected} className="img" alt=""></img>;
  }

  render() {
    return (
      <div className="step-home">
        <h2 className="mb-3 text-bold">
          {this.state.strings[this.state.country].home.que_es.h4}
        </h2>
        
        <div className="row row-eq-height">
          <div className="col-xs-12 col-sm-8 home-text-container">
            {this.state.strings[this.state.country].home.que_es.text.map(
              (value, i) => {
                return (
                  <p
                    className="mb-2 text-justify"
                    key={i}
                    dangerouslySetInnerHTML={{ __html: value }}
                  />
                );
              }
            )}

            <p className="mb-2 text-justify">
              {this.state.strings[this.state.country].home.footer[0]}{" "}
              <span className="icon-Hand_off" />
            </p>

            <div className="icon_grid">
              {this.state.strings.icon_grid.map((value, i) => {
                return (
                  <div key={i}>
                    {this.getImage(value.key)}
                    <p>{value.texto}</p>
                  </div>
                );
              })}
            </div>

            <div className="disclaimer-GDPR pb-1 pt-1">
              <p
                dangerouslySetInnerHTML={{
                  __html: this.state.strings[this.state.country].home
                    .disclaimerGDPR,
                }}
              />
            </div>
          </div>

          <div className="hidden-xs col-sm-4">
            <img src={illustration} className="home-img" style={{ width: "100%" }} alt="" />
          </div>
        </div>
        {this.state.navigation}
      </div>
    );
  }
}
