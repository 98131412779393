import React, { Component } from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  LabelList,
  Label,
} from "recharts";

export default class MotivationChart extends Component {
  constructor(props) {
    super(props);

    if (window.innerWidth >= 600) {
      this.state = {
        width: this.props.width || 600,
        height: this.props.height || 500,
      };
    } else {
      this.state = {
        width: window.innerWidth - 20,
        height: Math.ceil((window.innerWidth * 500) / 600) - 20,
      };
    }
  }

  Tooltip = ({ active, payload, label }) => {
    if (active && payload[0]) {
      let { title, description, rating, order } = payload[0].payload.value;
      return (
        <div className="tooltip">
          <span className="title">
            {order}º {title}
          </span>
          <span className="description">{description}</span>
          <div className="rating">
            {Array(rating)
              .fill()
              .map((_, i) => (
                <span key={i}>&#9733;</span>
              ))}
          </div>
        </div>
      );
    }

    return null;
  };

  render() {
    return (
      <ScatterChart
        width={this.state.width}
        height={this.state.height}
        margin={{ top: 50, right: 120, bottom: 30, left: 50 }}
      >
        <CartesianGrid />
        <XAxis
          type="number"
          dataKey="x"
          domain={[1, 10]}
          tickCount={10}
          axisLine={false}
          tickLine={false}
          tickFormatter={() => ""}
        />
        <YAxis
          type="number"
          dataKey="y"
          domain={[1, 12]}
          tickCount={12}
          axisLine={false}
          tickLine={false}
          tickFormatter={() => ""}
        ></YAxis>
        <ReferenceLine x={5.5} stroke="black">
          <Label
            value="Idealmente más importante"
            position="top"
            offset={20}
            style={{ fontSize: 12, fontWeight: "bold" }}
          />
          <Label
            value="Idealmente menos importante"
            position="bottom"
            offset={20}
            style={{ fontSize: 12, fontWeight: "bold" }}
          />
        </ReferenceLine>
        <ReferenceLine y={6.5} stroke="black">
          <Label
            value="Peor satisfechos"
            position="left"
            offset={10}
            style={{ fontSize: 12, fontWeight: "bold" }}
          />
          <Label
            value="Mejor satisfechos"
            position="right"
            offset={10}
            style={{ fontSize: 12, fontWeight: "bold" }}
          />
        </ReferenceLine>
        <Tooltip content={<this.Tooltip />} />
        <Scatter data={this.props.data} fill="#2DCCCD">
          <LabelList
            dataKey="value.title"
            position="bottom"
            offset={2}
            style={{ fontSize: 11, fill: "#666" }}
          />
        </Scatter>
      </ScatterChart>
    );
  }
}
