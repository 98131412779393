import React, { Component } from "react";
import Navigation from "../../../components/Navigation";
import { returnString } from "../strings";
import illustration from "../../../images/intro.jpg";
import calendar from "../../../images/calendar.svg";
import security from "../../../images/security.svg";
import chronometer from "../../../images/chronometer.svg";
import download_certificate from "../../../images/download_certificate.svg";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tokenType: this.props.data.tokenType,
      strings: null,
      country: this.props.data.country,
      navigation: <Navigation next="/survey/step-demographics" />,
      context: this.props.data.context,
      applicantName: this.props.data.applicantName,
      email: this.props.data.userEmail,
      vacancyName: this.props.data.vacancyName
    };
  }

  UNSAFE_componentWillMount() {
    let strings = returnString(this.state.tokenType, this.state.context, this.state.applicantName, this.state.email, this.state.vacancyName);
    let nav = <Navigation next="/survey/step-demographics" />;

    this.setState({
      strings,
      navigation: nav,
    });
  }

  getImage(key) {
    let selected = null;
    switch (key) {
      case "security":
        selected = security;
        break;
      case "duration":
        selected = chronometer;
        break;
      case "calendar":
        selected = calendar;
        break;
      case "download":
        selected = download_certificate;
        break;
      default:
        break;
    }
    return <img src={selected} className="img" alt=""></img>;
  }

  render() {
    return (
      <div className="step-home">
        <h2 className="mb-3 text-bold">
          {this.state.strings[this.state.country].home.h2}
        </h2>

        <div className="row row-eq-height">
          <div className="col-xs-12 col-sm-8 home-text-container">
            <h4 className="mb-2 text-bold">
              {this.state.strings[this.state.country].home.que_es.h4}
            </h4>

            {this.state.strings[this.state.country].home.que_es.text.map(
                (value, i) => {
                  return (
                    <p
                      className="mb-2 text-justify"
                      key={i}
                      dangerouslySetInnerHTML={{ __html: value }}
                    />
                  );
                }
              )}

            <div className="icon_grid">
              {this.state.strings.icon_grid.map((value, i) => {
                return (
                  <div key={i}>
                    {this.getImage(value.key)}
                    <p>{value.texto}</p>
                  </div>
                );
              })}
            </div>

            <div className="disclaimer-GDPR pb-1 pt-1">
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    this.state.strings[this.state.country].home.disclaimerGDPR,
                }}
              />
              </div>
          </div>

          <div className="hidden-xs col-sm-4">
            <img
              src={illustration}
              className="home-img"
              style={{ width: "100%" }}
              alt=""
            />
          </div>
        </div>

        {this.state.navigation}
      </div>
    );
  }
}
