import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import DataWrapper from "./components/DataWrapper";
// import { properties } from "./properties.js";
import "./styles/index.scss";

export default class App extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/survey" component={DataWrapper} />
        </Switch>
      </BrowserRouter>
    );
  }
}
