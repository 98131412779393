import React from "react";
import Navigation from "../../components/Navigation";

export default function (props) {
  return (
    <div className="text-center">
      <p className="mb-5">
        <span className="icon-cross text-error f-s-100" />
      </p>
      <p className="mb-3 f-s-20">
        Ha ocurrido un error al enviar el formulario.
      </p>
      <p className="mb-5 f-s-20">Por favor, inténtalo de nuevo.</p>
      <Navigation back={props.redirect} />
    </div>
  );
}
