import React, { Component } from "react";
import MotivationChart from "../../../components/MotivationsChart";
import StarRatings from "react-star-ratings";
import Pdf from "react-to-pdf";
import axios from "axios";
import { returnString } from "../strings";
import { properties } from "../../../properties.js";
import download_certificate from "../../../images/download_certificate.svg";
// import { Popover, PopoverBody } from "reactstrap";

const { error } = console;
const ref = React.createRef();
let pdfOptions = {
  orientation: "portrait",
};

/*if (window.innerWidth > 767) {
  pdfOptions = {
    orientation: "landscape",
  };
} else {
  pdfOptions = {
    orientation: "portrait",
  };
}*/

export default class Success extends Component {
  constructor(props) {
    super(props);

    let motivatorsStatusList = JSON.parse(
      localStorage.getItem("stepInfluence")
    );

    this.state = {
      tokenId: this.props.data.tokenId,
      campaignId: this.props.data.campaignId,
      discResult: this.props.data.discResult,
      tips: this.props.data.tips,
      results: this.parseResults(motivatorsStatusList),
      tipsRating: -1,
      tipsRatingComments: "",
      tipsValue: -1,
      tipsValueComments: "",
      // tipsDISC: -1,
      // tipsDISCComments: "",
      tipsSent: false,
      country: this.props.data.country,
      tokenType: this.props.data.tokenType,
      strings: null,
      context: this.props.data.context,
      isPopoverOpen: false,
      currentDate: new Date().toISOString().slice(0, 10),
    };
  }

  UNSAFE_componentWillMount() {
    let strings = returnString(this.state.tokenType, this.state.context);

    this.setState({
      strings: strings,
    });
  }

  parseResults(data) {
    return data.map((item, index) => {
      return {
        x: item.rating,
        y: data.length - index,
        value: {
          title: item.title.split(" ")[0],
          description: item.description,
          rating: item.rating,
          order: index + 1,
        },
      };
    });
  }

  changeTipsRating = (val) => {
    this.setState((state) => {
      return { ...state, tipsRating: val };
    });
  };

  changeTipsRatingComments = (event) => {
    const { value } = event.target;
    this.setState((state) => {
      return { ...state, tipsRatingComments: value };
    });
  };

  changeTipsValue = (val) => {
    this.setState((state) => {
      return { ...state, tipsValue: val };
    });
  };

  changeTipsValueComments = (event) => {
    const { value } = event.target;
    this.setState((state) => {
      return { ...state, tipsValueComments: value };
    });
  };

  /*
  changeTipsDISC = (val) => {
    this.setState((state) => {
      return { ...state, tipsDISC: val };
    });
  };

  changeTipsDISCComments = (event) => {
    const { value } = event.target;
    this.setState((state) => {
      return { ...state, tipsDISCComments: value };
    });
  };
  */

  setIsPopoverOpen = (val) => {
    this.setState({
      isPopoverOpen: val,
    });
  };

  togglePopover = () => this.setIsPopoverOpen(!this.state.isPopoverOpen);

  /*
  getDISCobjs = () => {
    let high = [];
    let low = [];

    ["D", "I", "S", "C"].forEach((type) => {
      let t = {
        type,
        name: this.state.strings[this.state.country].success.disc.tips[type]
          .name,
        mean: this.state.discResult[type].mean,
        std: this.state.discResult[type].std,
        moderate:
          this.state.discResult[type].mean > 25 &&
          this.state.discResult[type].mean < 75,
      };

      if (this.state.discResult[type].mean > 50) {
        t.tip = this.state.strings[this.state.country].success.disc.tips[
          type
        ].high;
        high.push(t);
      } else {
        t.tip = this.state.strings[this.state.country].success.disc.tips[
          type
        ].low;
        low.push(t);
      }
    });

    high = high.sort((a, b) => b.mean - a.mean);
    low = low.sort((a, b) => a.mean - b.mean);

    return { high, low };
  };

  getFriendlyDISC = (obj) => {
    return (
      obj.high.map((i) => i.type).join("") +
      " / " +
      obj.low.map((i) => i.type).join("")
    );
  };
  */

  scrollTop = () => {
    window.scrollTo(0, 0);
  };

  componentDidMount() {
    this.scrollTop();

    /*
    const discObj = this.getDISCobjs();
    this.setState({
      discObj,
      discFriendly: this.getFriendlyDISC(discObj),
    });
    */

    /* 
      clear localStorage should be done after Success step is mount
      because the plot need stepInfluence to be filled
    */
    localStorage.clear();
  }

  onSubmitTipsRating = async () => {
    let data = {
      tips_rating: this.state.tipsRating,
      tips_rating_comments: this.state.tipsRatingComments,
      tips_value: this.state.tipsValue,
      tips_value_comments: this.state.tipsValueComments,
      // tips_disc: this.state.tipsDISC,
      // tips_disc_comments: this.state.tipsDISCComments,
      token_id: this.state.tokenId,
      campaign_id: this.state.campaignId,
    };

    try {
      await axios.put(properties.host + `responses`, data);

      this.setState((state) => {
        return { ...state, tipsSent: true };
      });
    } catch (e) {
      error(e);
    }
  };

  render() {
    return (
      <div className="success">
        <div className="mb-4">
          <h2 className="mb-3">
            {this.state.strings[this.state.country].success.h2.bold.map(
              (value, i) => {
                return (
                  <span
                    className="text-bold"
                    key={i}
                    dangerouslySetInnerHTML={{ __html: value }}
                  />
                );
              }
            )}
            {this.state.strings[this.state.country].success.h2.text.map(
              (value, i) => {
                return (
                  <span
                    className="text-bold mb-1"
                    key={i}
                    dangerouslySetInnerHTML={{ __html: value }}
                  />
                );
              }
            )}
          </h2>
        </div>

        <div ref={ref} className="results-box">
          <div className="text-center">
            <p className="text-bold p-2">
              Mis Motivators ({this.state.currentDate})
            </p>

            <div className="chart">
              <MotivationChart data={this.state.results} />
            </div>
          </div>

          <div className="description text-left">
            {this.state.strings[
              this.state.country
            ].success.chart.description.map((value, i) => {
              return (
                <p
                  className="text-bold text-left"
                  key={i}
                  dangerouslySetInnerHTML={{ __html: value }}
                />
              );
            })}

            <div className="tip-list">
              {this.state.tips &&
                this.state.tips.map((t, i) => (
                  <p key={i} className="mb-1 text-justify">
                    {t}.
                  </p>
                ))}
            </div>

            <br></br>

            {/* 
            <hr></hr>
            <br></br>

            <p className="text-justify">
              <span className="text-bold">
                {
                  this.state.strings[this.state.country].success.disc
                    .description
                }
                :&nbsp;
              </span>
              <span>{this.state.discFriendly}&nbsp;</span>
            </p>

            <p>
              {this.state.strings[this.state.country].success.disc.disclaimer}
            </p>

            <p className="text-justify">
              {this.state.discObj &&
                this.state.discObj.high.map((value, i) => {
                  return (
                    <span key={i}>
                      <span>
                        <span className="text-bold">
                          Nivel de {value.name}:&nbsp;
                        </span>
                        {value.moderate ? "moderadamente alto" : "alto"}
                        <br />
                        <span className="text-justify">{value.tip}</span>
                      </span>
                      <br />
                      <br />
                    </span>
                  );
                })}

              {this.state.discObj &&
                this.state.discObj.low.map((value, i) => {
                  return (
                    <span key={i}>
                      <span>
                        <span className="text-bold">
                          Nivel de {value.name}:&nbsp;
                        </span>
                        {value.moderate ? "moderadamente bajo" : "bajo"}
                        <br />
                        <span className="text-justify">{value.tip}</span>
                      </span>
                      <br />
                      <br />
                    </span>
                  );
                })}
            </p>
            */}
          </div>
        </div>

        <div className="results-box results-box--transparent">
          <div className="mt-2 mb-2 text-center">
            <Pdf
              targetRef={ref}
              filename={
                "resultados-motivators-" + this.state.currentDate + ".pdf"
              }
              options={pdfOptions}
              scale={0.8}
            >
              {({ toPdf }) => (
                <button
                  className="btn btn-primary text-center"
                  type="button"
                  onClick={() => {
                    this.scrollTop();
                    toPdf();
                  }}
                >
                  <img
                    src={download_certificate}
                    className="text-center btn-img"
                    alt=""
                  ></img>
                  <p className="text-bold">
                    {
                      this.state.strings[this.state.country].success.chart
                        .download
                    }
                  </p>
                </button>
              )}
            </Pdf>
          </div>
        </div>

        <div className="results-box text-center pt-1 p-2">
          <div className="left-container"></div>

          <div className="description">
            <div>
              {this.state.strings[
                this.state.country
              ].success.valoracion.description.map((value, i) => {
                return (
                  <p
                    className="text-bold clear mb-1"
                    key={i}
                    dangerouslySetInnerHTML={{ __html: value }}
                  />
                );
              })}

              <StarRatings
                rating={this.state.tipsRating}
                changeRating={this.changeTipsRating}
                numberOfStars={4}
                name="tipsRating"
                starDimension="30px"
                starEmptyColor="#D3D3D3"
                starHoverColor="#02A5A5"
                starRatedColor="#02A5A5"
              />

              <textarea
                className="mt-2"
                placeholder={
                  this.state.strings[this.state.country].success.placeholders[0]
                }
                name="tipsRatingComments"
                value={this.state.tipsRatingComments}
                onChange={this.changeTipsRatingComments}
              />

              {this.state.strings[
                this.state.country
              ].success.valoracion.valor.map((value, i) => {
                return (
                  <p
                    className="text-bold clear mt-4 mb-1"
                    key={i}
                    dangerouslySetInnerHTML={{ __html: value }}
                  />
                );
              })}

              <StarRatings
                rating={this.state.tipsValue}
                changeRating={this.changeTipsValue}
                numberOfStars={4}
                name="tipsValue"
                starDimension="30px"
                starEmptyColor="#D3D3D3"
                starHoverColor="#02A5A5"
                starRatedColor="#02A5A5"
              />

              <textarea
                className="mt-2"
                placeholder={
                  this.state.strings[this.state.country].success.placeholders[0]
                }
                name="tipsValueComments"
                value={this.state.tipsValueComments}
                onChange={this.changeTipsValueComments}
              />

              {/*
              {this.state.strings[
                this.state.country
              ].success.valoracion.disc.map((value, i) => {
                return (
                  <p
                    className="text-bold clear mt-4 mb-1"
                    key={i}
                    dangerouslySetInnerHTML={{ __html: value }}
                  />
                );
              })}

              <StarRatings
                rating={this.state.tipsDISC}
                changeRating={this.changeTipsDISC}
                numberOfStars={4}
                name="tipsDISC"
                starDimension="30px"
                starEmptyColor="#D3D3D3"
                starHoverColor="#02A5A5"
                starRatedColor="#02A5A5"
              />

              <textarea
                className="mt-2"
                placeholder={
                  this.state.strings[this.state.country].success.placeholders[0]
                }
                name="tipsDISCComments"
                value={this.state.tipsDISCComments}
                onChange={this.changeTipsDISCComments}
              />
              */}

              {!this.state.tipsSent && (
                <div className="text-center mt-2">
                  <button
                    className="btn btn-primary"
                    onClick={this.onSubmitTipsRating}
                    disabled={
                      this.state.tipsRating !== -1 ||
                      this.state.tipsValue !== -1 ||
                      this.state.tipsDISC !== -1
                        ? false
                        : true
                    }
                  >
                    {
                      this.state.strings[this.state.country].success.valoracion
                        .enviar
                    }
                  </button>
                </div>
              )}

              {this.state.tipsSent && (
                <div className="text-center text-bold mt-2">
                  <p>
                    {
                      this.state.strings[this.state.country].success.valoracion
                        .enviado
                    }
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
