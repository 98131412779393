import React, { Component } from "react";
import axios from "axios";
import qs from "query-string";
import { Route, withRouter } from "react-router-dom";

// Routes
import Home from "../../../routes/nextmotivators/Home";
import StepDemographics from "../../../routes/nextmotivators/StepDemographics";
import StepPriorities from "../../../routes/nextmotivators/StepPriorities";
import StepRatings from "../../../routes/nextmotivators/StepRatings";
import StepFinal from "../../../routes/nextmotivators/StepFinal";
// import StepDisc from "../../../routes/nextmotivators/StepDisc";
import StepInfluence from "../../../routes/nextmotivators/StepInfluence";
import Success from "../../../routes/nextmotivators/Success";
import Error from "../../../routes/Error";

// Components
import { GenericModal, LoadingModal } from "../../Modals";
import { properties } from "../../../properties.js";
import { 
  // DISC_QUESTIONS,
  MOTIVATORS_LIST 
} from "../../../routes/constants";

const { error } = console;

// reset cache to prevent previous campaigns data to be sent to server
localStorage.clear();


const stepDemographics = JSON.parse(localStorage.getItem("stepDemographics"));
// const stepDisc = JSON.parse(localStorage.getItem("stepDisc"));
const stepPriorities = JSON.parse(localStorage.getItem("stepPriorities"));
const stepRatings = JSON.parse(localStorage.getItem("stepRatings"));
const stepInfluence = JSON.parse(localStorage.getItem("stepInfluence"));

class NextMotivators extends Component {
  constructor(props) {
    super(props);

    const tokenType = props.tokenType;
    const country = props.country;
    const campaignId = props.campaignId;
    const { tokenId } = qs.parse(props.location.search);
    /*let defaultDiscObj = {};
    DISC_QUESTIONS.forEach(
      (q) =>
        (defaultDiscObj[q.id] = {
          value: q.min,
          type: q.type,
          min: q.min,
          max: q.max,
        })
    );
    */

    this.state = {
      tokenId,
      tokenType,
      country,
      campaignId,
      context: this.props.data.context,
      applicantName: this.props.data.applicant,
      vacancyName: this.props.data.vacancy,

      // Step Demographics
      alreadyIdentified: false,
      age: null,
      genre: null,
      planet: null,
      level: null,
      workplace: null,
      ascriptionplace: null,
      worklength: null,
      project_level_1: null,
      project_level_2: null,
      // workinternational: null,
      cd: null,

      // Step DISC
      /*
      disc: defaultDiscObj,
      discResult: null,
      */

      // Step Priorities
      alreadySorted: false,
      list:
        !!stepPriorities && stepRatings === null
          ? stepPriorities
          : [
              ...MOTIVATORS_LIST.map((motivator) => ({
                ...motivator,
                ...{ rating: 0 },
                ...{ comments: "" },
              })),
            ],

      // Step Final
      alreadyPushedCaptcha: false,
      // rating: 0,
      enps: 0,
      // comments: "",
      userEmail: props.email ? props.email.toLowerCase().trim() : "",
      consent: false,
      // from
      // from_comment: "",
      // next_assign
      // next_assign_comment: "",
      // no_project
      // no_project_times
      // staffing_rating
      // staffing_rating_comment: "",
      // dedication
      // dedication_comment: "",

      // Success
      tips: null,

      // Modals
      showLoadingModal: false,
      modal: {
        type: "",
        name: "",
        desc: "",
        show: false,
      },
    };
  }

  /* MODALS */
  showModal = (type, title, desc) => {
    this.setState({
      modal: {
        type,
        name: title,
        desc,
        show: true,
      },
      showLoadingModal: false,
    });
  };

  hideModal = () => {
    this.setState((prevState) => ({
      modal: {
        ...prevState.modal,
        show: false,
      },
      showLoadingModal: false,
    }));
  };

  toggleLoading = (status) => {
    this.setState({ showLoadingModal: status });
  };
  /* END MODALS */

  onChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  filterResponse = () => {
    let result = {
      tokenId: this.state.tokenId,
      tokenType: this.state.tokenType,
      country: this.state.country,
      campaignId: this.state.campaignId,

      // Step Demographics
      age: this.state.age,
      genre: this.state.genre,
      planet: this.state.planet,
      level: this.state.level,
      workplace: this.state.workplace,
      ascriptionplace: this.state.ascriptionplace,
      worklength: this.state.worklength,
      project_level_1: this.state.project_level_1,
      project_level_2: this.state.project_level_2,
      // workinternational: this.state.workinternational,
      cd: this.state.cd,

      // Step DISC
      /*
      disc: this.state.disc,
      */

      // Step Priorities
      list: this.state.list,

      // Step Final
      // rating: this.state.rating,
      enps: this.state.enps,
      // comments: this.state.comments,
      userEmail: this.state.userEmail.toLowerCase().trim(),
      consent: this.state.consent ? 1 : 0,
    };

    return result;
  };

  getMotivationTips = async () => {
    try {
      const responseTips = await axios.get(properties.host + "tips", {
        params: {
          token: this.state.tokenId,
          country: this.state.country,
          campaign_id: this.state.campaignId,
        },
      });
      this.onChange("tips", responseTips.data);
    } catch (eTips) {
      error(eTips);
      this.showModal(
        "error",
        "Error",
        "No se pudo obtener el conjunto de hipótesis sobre motivación"
      );
    }
  };

  /*
  getDISC = async () => {
    try {
      const responseDisc = await axios.get(properties.host + "disc", {
        params: {
          token: this.state.tokenId,
          country: this.state.country,
          campaign_id: this.state.campaignId,
        },
      });

      this.onChange("discResult", responseDisc.data);
    } catch (eDisc) {
      error(eDisc);
      this.showModal("error", "Error", "No se pudo obtener el perfil personal");
    }
  };
  */

  onSubmit = async () => {
    const data = this.filterResponse();

    if (!!stepDemographics) {
      for (const property in stepDemographics) {
        data[property.toString()] = stepDemographics[property].toString();
      }
    }

    if (!!stepRatings) {
      data["list"] = stepRatings;
    }

    /*
    if (!!stepDisc) {
      data["disc"] = stepDisc;
    }
    */

    if (!!stepInfluence) {
      data["list"] = stepInfluence;
    }

    // post response
    try {
      this.toggleLoading(true);

      await axios.post(properties.host + "responses", data);
      await this.getMotivationTips();
      // await this.getDISC();

      /* 
        clear localStorage should be done after Success step is mount
        because the plot need state.list and state.list could be empty if 
        user reloads page after influence step
        // localStorage.clear();
      */

      this.toggleLoading(false);

      // Redirect to OK page
      this.props.history.push(
        `/survey/success?tokenId=${this.state.tokenId}&campaign_id=${this.state.campaignId}`
      );
    } catch (e) {
      error(e);
      this.showModal("error", "Error", "No se pudo enviar el cuestionario");
    }
  };

  render() {
    return (
      <>
        <LoadingModal show={this.state.showLoadingModal} />

        <GenericModal
          type={this.state.modal.type}
          name={this.state.modal.name}
          desc={this.state.modal.desc}
          show={this.state.modal.show}
          callback={() => this.hideModal()}
        />

        <Route exact path="/survey">
          <Home data={this.state} />
        </Route>

        <Route path="/survey/step-demographics">
          <StepDemographics data={this.state} onChange={this.onChange} />
        </Route>

        {/* 
        <Route path="/survey/step-disc">
          <StepDisc data={this.state} onChange={this.onChange} />
        </Route>
        */}

        <Route path="/survey/step-priorities">
          <StepPriorities data={this.state} onChange={this.onChange} />
        </Route>

        <Route path="/survey/step-ratings">
          <StepRatings data={this.state} onChange={this.onChange} />
        </Route>

        <Route path="/survey/step-influence">
          <StepInfluence data={this.state} onChange={this.onChange} />
        </Route>

        <Route path="/survey/step-final">
          <StepFinal
            data={this.state}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
          />
        </Route>

        <Route exact path="/survey/error">
          <Error redirect="/step-final" />
        </Route>

        <Route exact path="/survey/success">
          <Success data={this.state} />
        </Route>
      </>
    );
  }
}

export default withRouter(NextMotivators);
