import React, { Component } from "react";
// import StarRatings from "react-star-ratings";
import Checkbox from "../../../components/Checkbox";
import Navigation from "../../../components/Navigation";
// import SelectOption from "../../../components/SelectOption";
import ReCAPTCHA from "react-google-recaptcha";
// https://reactjsexample.com/a-simple-calendar-component-for-react-based-applications/
// import SimpleReactCalendar from "simple-react-calendar";
import { returnString } from "../strings";
import {
  MAIL_DOMAIN,
} from "../../constants";
import { properties } from "../../../properties.js";
import { emailIsValid } from "../../../helpers/validators";

const recaptchaRef = React.createRef();

export default class StepFinal extends Component {
  constructor(props) {
    super(props);

    let requiredFields = [];

    this.state = {
      requiredFields,
      dirtyFields: [],
      tokenType: this.props.data.tokenType,
      back_path: "/survey/step-influence",
      country: this.props.data.country,
      strings: null,
      context: this.props.data.context,
      validUserEmail: true,
    };
  }

  UNSAFE_componentWillMount() {
    let strings = returnString(this.state.tokenType, this.state.context);

    this.setState({
      strings: strings,
    });
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /*
  changeComments = (event) => {
    const value = event.target.value;
    this.props.onChange("comments", value);
  };
  */

  changeProp = (val, name) => {
    this.props.onChange(name, val);
  };

  changeConsentCheckboxValue = (event) => {
    let { name, value } = event.target;
    this.props.onChange(name, value);
  };

  /*
  changeCheckbox = (event) => {
    const { name, checked } = event.target;
    this.props.onChange(name, checked);
  };
  */

  changeValue = (event) => {
    let { name, value } = event.target;
    this.props.onChange(name, value);
    if (this.state.requiredFields.indexOf(name) >= 0) {
      if (this.state.dirtyFields.indexOf(name) === -1) {
        this.state.dirtyFields.push(name);
      }
    }

    if (name === "userEmail") {
      if (value) {
        value = value.trim();
        this.setState({
          validUserEmail:
            value.length &&
            emailIsValid(value) &&
            value.toLowerCase().endsWith(MAIL_DOMAIN),
        });
      } else {
        this.setState({ validUserEmail: true });
      }
    }
  };

  changeList = (event) => {
    let { name, value } = event.target;
    var options = event.target.options;
    value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }

    this.props.onChange(name, value);
    if (this.state.requiredFields.indexOf(name) >= 0) {
      if (this.state.dirtyFields.indexOf(name) === -1) {
        this.state.dirtyFields.push(name);
      }
    }
  };

  captchaChange = (value) => {
    this.setState({ value });
    if (value === null) this.setState({ expired: "true" });
    this.props.onChange("alreadyPushedCaptcha", true);
  };

  onSubmit = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    this.props.onSubmit(recaptchaValue);
  };

  validate(strings) {
    if (this.props.data.alreadyPushedCaptcha && this.state.validUserEmail) {
      return (
        <button
          className="btn btn-primary finish-button"
          onClick={this.onSubmit}
        >
          {this.state.strings[this.state.country].stepFinal.btn_enviar}
          &nbsp;&nbsp;
          <i className="icon-play2"></i>
        </button>
      );
    } else {
      return null;
    }
  }

  setNavigation() {
    const completed =
      this.props.data.rating !== 0 &&
      // this.props.data.enps !== 0 &&
      (this.state.requiredFields.length === 0 ||
        this.state.dirtyFields.length === this.state.requiredFields.length);

    if (
      completed &&
      this.props.data.alreadyPushedCaptcha &&
      this.state.validUserEmail
    ) {
      return (
        <Navigation
          back={this.state.back_path}
          finish={this.validate(this.state.strings)}
        />
      );
    } else {
      return <Navigation back={this.state.back_path} />;
    }
  }

  render() {
    return (
      <div className="step-final">
        <h2 className="mb-3 text-bold">
          {this.state.strings[this.state.country].stepFinal.h2}
        </h2>
        <div className="bg-grey">
          {/*<div className="select-container">
            <div className="block text-center">
              <h4 className="text-bold">
                {this.state.strings[
                  this.state.country
                ].stepFinal.rating.text.map((value, i) => {
                  return (
                    <span key={i} dangerouslySetInnerHTML={{ __html: value }} />
                  );
                })}
              </h4>
              <div className="text-center mt-1">
                <StarRatings
                  rating={this.props.data.rating}
                  changeRating={this.changeProp}
                  numberOfStars={5}
                  name="rating"
                  starDimension="40px"
                  starEmptyColor="#D3D3D3"
                  starHoverColor="#02A5A5"
                  starRatedColor="#02A5A5"
                />
              </div>
            </div>
          </div>*/}

          <div className="clear --x--mt-4 --x--mb-4 --x--model-participation">
            {this.state.strings[
              this.state.country
            ].stepFinal.modelo.title.map((value, i) => {
              return (
                <b key={i} dangerouslySetInnerHTML={{ __html: value }} />
              );
            })}
            <br></br>
            <br></br>
            {this.state.strings[
              this.state.country
            ].stepFinal.modelo.bloque1.map((value, i) => {
              return (
                <span key={i} dangerouslySetInnerHTML={{ __html: value }} />
              );
            })}
            <br></br>
            <br></br>
            {this.state.strings[
              this.state.country
            ].stepFinal.modelo.bloque2.map((value, i) => {
              return (
                <span key={i} dangerouslySetInnerHTML={{ __html: value }} />
              );
            })}
            <br></br>
            <br></br>
            {this.state.strings[
              this.state.country
            ].stepFinal.modelo.bloque3.map((value, i) => {
              return (
                <span key={i} dangerouslySetInnerHTML={{ __html: value }} />
              );
            })}
            <br></br>
            <div className="mt-2 mb-1">
              <input
                style={{ width: "400px" }}
                type="text"
                name="userEmail"
                placeholder={
                  this.state.strings[this.state.country].stepFinal
                    .placeholders[2]
                }
                value={this.props.data.userEmail}
                onChange={this.changeValue}
              />
              {this.props.data.alreadyPushedCaptcha &&
                !this.state.validUserEmail && (
                  <p className="field-warning">
                    {
                      this.state.strings[this.state.country].stepFinal
                        .userEmailCheck
                    }
                  </p>
                )}
            </div>
            <div className="mt-2 mb-1">
                <Checkbox 
                  name="consent"
                  onChangeValue={(val) => {this.changeConsentCheckboxValue({target: {name:"consent", value: val}})} }
                  initialState={this.props.data.consent}
                  title={this.state.strings[this.state.country].stepFinal.consent.link}></Checkbox>
            </div>
          </div>

          <div className="text-center mt-4 mb-2">
            <ReCAPTCHA
              className="captcha-center"
              ref={recaptchaRef}
              sitekey={properties.idCaptcha}
              onChange={this.captchaChange}
            />
          </div>
        </div>

        {this.setNavigation()}
      </div>
    );
  }
}
