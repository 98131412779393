export const returnString = (position, applicant) => {
  return getRecruitingStrings(position, applicant);
};

const GDPR =
  "<b>Nota informativa sobre protección de datos de carácter personal:</b><br>La información que nos facilites será tratada de manera confidencial como parte del proceso de selección en el que estás participando con nosotros, única y exclusivamente con la finalidad de poder llevar a cabo un mejor encaje de tu candidatura con las posiciones que podamos tener disponibles.";

const DISC_STEP = {
  h2: "Para calentar",
  text: [
    "En primer lugar, te invitamos a que te identifiques con aquel término que mejor te defina.",
  ],
};

const getRecruitingStrings = (position, applicant) => {
  return {
    icon_grid: [
      {
        icon: "icon-clock",
        key: "duration",
        texto: "Menos de 5 minutos",
      },
    ],
    ES: {
      home: {
        h2: "",
        que_es: {
          h4: "¿Qué es Talent Magnets Motivators?",
          text: [
            `¡Hola <b>${applicant}</b>!`,
            `Gran parte del éxito del proceso de selección en el que estás participando reside en nuestra capacidad para lograr que puedas estar en el proyecto que mejor encaje con tus expectativas, siempre dentro de nuestras posibilidades.`,
            "¿Nos ayudas con este sencillo juego a conocer un poco más sobre ti?",
          ],
        },
        disclaimerGDPR: GDPR,
        footer: ["¡Muchas gracias!"],
      },
      stepDisc: DISC_STEP,
      stepPriorities: {
        h2: "En un trabajo ideal ¿cuáles serían tus prioridades?",
        text: [
          "Mueve los elementos a la caja inferior y ordena de mayor importancia a menor importancia este listado.",
        ],
        sortable_text:
          "Arrastra <b>todos</b> los elementos a esta caja para poder continuar.",
        btn_enviar: "Enviar encuesta",
      },
      success: {
        h2: "¡Gracias por participar!",
      },
    }  
  };
};
