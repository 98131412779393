import React, { Component } from "react";

export default class Checkbox extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isChecked: props.initialState,
      };
    }

    toggleChange = () => {
        const newVal = !this.state.isChecked
        this.setState({
            isChecked: newVal,
        });
        this.props.onChangeValue(newVal);
    }
    
    render() {
      return (
        <label>
          <input type="checkbox"
            defaultChecked={this.state.isChecked}
            onChange={this.toggleChange}
          />
          <span className="ml-1"
            dangerouslySetInnerHTML={{ __html: this.props.title }}
          />
        </label>
      );
    }
  }
  
